import React from 'react';
import ReactTable from "react-table";
import "./table.css";
import Pagination from "./pagination";
import EditUserPopUp from "../../containers/Team/components/EditUserPopUp";
import moment from 'moment';
import _ from 'lodash';

const editIcon = require("../../assets/icons/edit_icon.png")

class TeamDatatable extends React.Component {
  constructor() {
    super();
    this.state = {
     
    };
  }

  render() {
    
    return (
      <div className="team_data_table">
        <ReactTable
          resizable={false}
          data={this.props.data}
          loading={this.props.loading}
          minRows={this.props.data.length}
          columns={[
            {
              columns: [
                {
                  Header: "Name",
                  accessor:"name",
                  className: "primary-normal-font font-size-15 primary-text-color",
                  maxWidth: 200
                },
                {
                  Header: "Email",
                  accessor: "email",
                  className: "primary-normal-font font-size-15 primary-text-color",
                  maxWidth: 300,
                  minWidth:300
                },
                {
                  Header: "Email Type(s)",
                  accessor: "email_type",
                  className: "primary-normal-font font-size-15 primary-text-color",
                  maxWidth: 200,
                  Cell:cellInfo=>{
                    return <span>{cellInfo.original.isAdmin===true? "admin" : cellInfo.original.email_type}</span>}
                },
                {
                  Header:"",
                  Cell: cellInfo => <EditUserPopUp handleDelete={this.props.handleDelete} handleUpdate={this.props.handleUpdate} data={cellInfo.original}/>
                },
              ]
            },
            
          ]}
          PaginationComponent={props => <Pagination {...props} />}
          defaultPageSize={10}
          defaultSorted={[{
            id   : 'email_type',
            desc : false,
          }]}
          className="-striped "
        />
        <br />
      </div>
    );
  }
}


export default TeamDatatable