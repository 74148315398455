import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from './store';
import { SnackbarProvider } from 'notistack';

ReactDOM.render(
  <Provider store={store}>
  <SnackbarProvider>
    <App />
    </SnackbarProvider>
  </Provider>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
