import React from 'react'
import StarRating from 'react-star-ratings'
import { Typography, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
const review_icon = require("../../assets/icons/profile_icon.png");
const RatingCell  = ({image,rating, name, date}) => {
    
    return(
       <Grid container spacing={16} direction="row" justify="flex-start" alignItems="center" className="margin-bottom-20px" >
          <Grid item>
              { image ? (
                <Avatar alt="User Image" src={image} className="height-80px width-80px"/>
               ) : (
                <Avatar alt="Profile Icon" src={review_icon} className="height-80px width-80px profile-icon avatar-bg-color"/>
               )}
           </Grid>
           <Grid item>
                <StarRating starDimension="20px" starSpacing="2px" rating={rating} numberOfStars={5} starRatedColor={"gray"}></StarRating>
            </Grid>
            <Grid item >
              <Grid item container direction="row" >
                    <Grid item> 
                        <Typography className="primary-medium-font primary-text-color margin-right-10px font-size-18">{name}</Typography>
                    </Grid>
                    <Grid item> 
                        <Typography className="primary-medium-font primary-text-color margin-right-10px font-size-18">|</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className="primary-medium-font primary-text-color font-size-18">{date}</Typography>
                    </Grid>
              </Grid>
            </Grid>
        </Grid>
    )
}
export default RatingCell 