import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Routes from "../../Routes/routes";
import Layout from "../../components/Layout/index";
import Login from "../../containers/Login/Login";
import Unsubscribe from '../../containers/Unsubscribe/index';
import EmailConfirmation from '../../containers/EmailConfirmation/index';

import ResetPassword from '../../containers/ResetPassword/index'
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getBusinessDataRequest,
  setSelectedBusiness,
  uploadBusinessImageRequest,
  setSelectedChain,
  clearMessage,
  getBusinessAccountsV2,
  updateBusinessAccountsV2
} from "../../actions/businessDataActions";
import {  resetIsAuthorized } from "../../actions/auth"
import AppDialog from "../../components/Dialog/index";
import _ from "lodash";
import { withSnackbar } from "notistack";
import { compose } from "recompose";

const ContactText = () => (
  <>
    If you'd like to speak with a member of our team, you can either reach out
    to your dedicated Seated account manager or email us at{" "}
    <a style={{ color: "#4d5869" }} href="mailto:partnerships@seatedapp.io">
      partnerships@seatedapp.io
    </a>
  </>
);
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: true,
      openDialog: false,
      termsAccepted: false,
      domain: this.isAuthorized()
        ? window.localStorage.getItem("domain")
        : null,
      domain_type: this.isAuthorized()
        ? window.localStorage.getItem("domain_type")
        : null
    };
  }
  
  componentDidMount() {
    this.isAuthorized() && this.props.getBusinessData()
    this.isAuthorized() && this.props.getBusinessAccountsV2()
    // console.log("App props:", this.props)
  }
  
  isAuthorized() {
    const token = window.localStorage.getItem("auth_token");
    if (_.isNull(token) || _.isEmpty(token) || token === "undefined") {
      return false;
    }

   if(this.props.isAuthorized === false){
     return false
   }
    if (!window.localStorage.getItem("domain_type")) {
      window.localStorage.removeItem("auth_token");
      return false;
    }
    
    return true;
  }

  setSelectedBusiness = id => {
    this.props.setSelectedBusiness(id);
    this.props.resetIsAuthorized()
  };
  setSelectedChain = id => {
    this.props.setSelectedChain(id);
  };
  dialogButton = () => {
    this.setState({ openDialog: !this.state.openDialog });
    window.gtag("event", "email_us_clicked")
  };
  handleContactModal = () => {
    this.setState({ openDialog: !this.state.openDialog });
    window.gtag("event", "contact_us_clicked")
  };
  //Costumize the SnackbarProvider:https://iamhosseindhv.com/notistack
  componentDidUpdate(prevProps) {
    if (this.props.authMessage !== prevProps.authMessage) {
      this.props.enqueueSnackbar(this.props.authMessage.message, {
        variant: this.props.authMessage.variant
      });
    }
    if (
      (this.props.message !== prevProps.message) &
      (this.props.message.message !== "")
    ) {
      this.props.enqueueSnackbar(this.props.message.message, {
        variant: this.props.message.variant
      });
    }
  }

  handleUpload = event => {
    let file = event.target.files[0];
    let data = new FormData();
    data.append("image", file);
    data.append("imageable_type", this.props.businessData.business.domain_type);
    data.append(
      "imageable_id",
      this.props.businessData.business.domain_type === "Chain"
        ? this.props.businessData.business.domain.id
        : this.props.businessData.selectedBusiness
    );

    this.props.uploadBusinessImage(data);
  };

  handleNotFound() {
    let domain_type = window.localStorage.getItem("domain_type");
    let domain = JSON.parse(window.localStorage.getItem("domain"));
    if (domain_type === '"Business"') {
      return `/business/${domain.id}/home`;
    } else {
      return `/chain/${domain.id}/home`;
    }
  }
  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/unsubscribe" component={Unsubscribe} />
            <Route exact path="/confirm_reservation" component={EmailConfirmation} />
            <Route exact path="/reject_reservation" component={EmailConfirmation} />
            <Route exact path="/password_reset/reset_password" component={ResetPassword} />
            <Route path="/login" component={Login} />
            {!this.isAuthorized() ? (
              <Redirect to="/login" />
            ) : this.props.businessData.isFetching ? (
              <React.Fragment>
                <CircularProgress
                  style={{ position: "fixed", bottom: "50%", right: "50%" }}
                />
              </React.Fragment>
            ) : (
              <Layout
                businessData={this.props.businessData}
                setSelectedBusiness={this.setSelectedBusiness}
                showContactModal={this.handleContactModal}
                handleUpload={this.handleUpload}
                setSelectedChain={this.setSelectedChain}
               
                unauthorized_code={
                  this.props.message && this.props.message.code
                }
                clearMessage={this.props.clearMessage}
                getBusinessAccounts={this.props.getBusinessAccountsV2}
                updateBusinessAccounts={this.props.updateBusinessAccountsV2}
                termsAccepted={this.state.termsAccepted}
              >
                <Switch>
                  {Routes.map(route => {
                    return (
                      <Route
                        key={route.path}
                        exact
                        path={`/:entity/:id/${route.path}`}
                        component={route.component}
                      />
                    );
                  })}
                  <Redirect to={this.handleNotFound()} />
                </Switch>
              </Layout>
            )}
          </Switch>
        </BrowserRouter>
        <AppDialog
          title={"Contact Us"}
          dialogButton={this.dialogButton}
          open={this.state.openDialog}
          handleClose={this.handleContactModal}
          buttonTitle={"Email Us"}
          emailButton={true}
          width="sm"
        >
          <ContactText />
        </AppDialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  businessData: state.businessData,
  authMessage: state.auth.message,
  message: state.businessData.message
});

const mapDispatchToProps = dispatch => {
  return {
    getBusinessData: () => {
      dispatch(getBusinessDataRequest());
    },
    setSelectedBusiness: id => {
      dispatch(setSelectedBusiness(id));
    },
    setSelectedChain: id => {
      dispatch(setSelectedChain(id));
    },
    clearMessage: () => {
      dispatch(clearMessage());
    },
    uploadBusinessImage: data => {
      dispatch(uploadBusinessImageRequest(data));
    },
    resetIsAuthorized: () => {
      dispatch(resetIsAuthorized());
    },
    getBusinessAccountsV2: () => {
      dispatch(getBusinessAccountsV2());
    },
    updateBusinessAccountsV2: () => {
      dispatch(updateBusinessAccountsV2());
    }
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withSnackbar
)(App);

App.propTypes = {
  auth: PropTypes.object,
  businessData: PropTypes.object,
  setSelectedBusiness: PropTypes.func,
  enqueueSnackbar: PropTypes.func.isRequired
};
