import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core";
import MuiDialog from "@material-ui/core/Dialog";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Avatar from '@material-ui/core/Avatar';
const receipt_icon = require("../../assets/icons/receipt_icon.png");

var Dialog2 = withStyles(_ => ({
  paper: { overflow: "visible" }
}))(MuiDialog);
class ShowImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
    if(this.props.reservationTable) {
      window.gtag("event", "history_reservation_receipt_viewed", this.props.rowDetails)
    } else if(this.props.orderTable) {
      window.gtag("event", "history_order_receipt_viewed", this.props.rowDetails)
    }
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };
  render() {
    const { images } = this.props
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <div onClick={this.handleClick}>
          <Avatar alt="Receipt Icon" src={receipt_icon} className="height-50px width-50px receipt-icon avatar-bg-color"/>
        </div>
        <Dialog2
          style={{ backgroundColor: "transparent", overflow: "visible" }}
          id="popper-image"
          open={open}
          onClose={this.handleClose}
        >
          <IconButton
            aria-label="Close"
            style={{
              position: "absolute",
              right: -50,
              top: -50,
              color: "white"
            }}
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Carousel showIndicators={false} showThumbs={false} dynamicHeight={true} width={"400px"}>
            {images.length > 0 &&
              images.sort((a,b) => b.id - a.id ).map((image, index) => 
                (
                  <div key={index}>
                    <img src={image} alt={index} />
                  </div>
                )
              )}
          </Carousel>
        </Dialog2>
      </div>
    );
  }
}
export default ShowImage;
