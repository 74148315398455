import { put, takeLatest } from "redux-saga/effects";
import { authSuccess } from "../actions/auth";
import { camelCaseKeysToSnakeCase } from "./helpers";
import {
  AUTH_REQUEST,
  AUTH_REQUEST_FAILED
} from "../actions/actionTypes";
import { Api } from "./Api";



function* authRequestSaga(action) {
  // yield put({ type: AUTH_REQUEST })
  try {
    const authResponse = yield Api.authRequest(action.payload);
    let result = camelCaseKeysToSnakeCase(authResponse);
    if (result.domain_business) {
      result.domain = result.domain_business;
    } else if (result.domain_chain) {
      result.domain = result.domain_chain;
    }
    window.localStorage.setItem("auth_token", result.jwt_token);
    window.localStorage.setItem("domain_type", JSON.stringify(result.domain_type));
    window.localStorage.setItem("domain", JSON.stringify(result.domain));
    window.localStorage.setItem("business_account_details", JSON.stringify(result.business_account));
    window.localStorage.setItem("master_login", JSON.stringify(result.master_login));
    if(result.domain_type == "Business") {
      let userProperties = JSON.parse(localStorage.getItem("user_properties"));
      if(!userProperties) {
        let userPropertiesDetails = {
          email: result.business_account.email,
          business: result.domain.name,
          business_id: result.domain.id,
          offers_dinein: result.domain.dine_in,
          offers_pickup: result.domain.pickup,
          offers_delivery: result.domain.delivery,
          status: result.domain.show_for_mobile,
          city: result.domain.city_name,
          price_rating: result.domain.price_rating,
          stage: result.domain.stage,
          restaurant_tags: result.domain.restaurant_tags,
          primary_cuisine: result.domain.primary_cuisine,
          allows_walkins: result.domain.walk_in,
          allows_reservations: result.domain.reservation,
          chargeable: result.domain.chargeable,
          total_seats: result.domain.total_seats,
          invoice_interval: result.domain.invoice_interval,
          base_reward_rate: (result.domain.rewards != undefined && result.domain.rewards.length > 0 && result.domain.rewards[0].base_reward),
          billing_rate: result.domain.bill_percentage,
          master_login: result.master_login
        }
        window.gtag('set', 'user_properties', userPropertiesDetails);
        window.localStorage.setItem("user_properties", true);
      }
    }
    yield put(authSuccess(result));
  } catch (e) {
    yield put({ type: AUTH_REQUEST_FAILED, payload:{ message: (typeof e !== "undefined" && e.find(r=> r).text) ? e.find(r=> r).text : "Something went wrong" , variant:'error'}});
  }
}

export function* watchAuthRequestSaga() {
  yield takeLatest(AUTH_REQUEST, authRequestSaga);
}
