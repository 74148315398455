import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  confirmReservation,
  rejectReservation
} from "../../actions/businessDataActions";

import queryString from "query-string";
const styles = {
  root: {
    padding: "24px",
    height: "220px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    position: "relative"
  }
};
function EmailConfirmation(props) {
  const { classes } = props;
  document.title = "Seated App";
  useEffect(() => {
    const values = queryString.parse(props.location.search);
    if (props.location.pathname === "/confirm_reservation") {
      props.confirmReservation(values.rid);
    }
    if (props.location.pathname === "/reject_reservation") {
        props.rejectReservation(values.rid);
      }
  }, []);

  return (
    <div className="width-50 margin-auto height-100vh display-flex flex-direction-column justify-content-center">
      <Paper className={classes.root}>
        {props.loading ? (
          <CircularProgress className="position-fixed bottom-50 right-50"/>
        ) : (
          <div className="display-flex flex-direction-column justify-content-center height-100">
            <Typography variant="h6" align="center" className="primary-normal-font primary-text-color font-size-26">
              { props.confirmationMessage }
            </Typography>
          </div>
        )}
      </Paper>
    </div>
  );
}

const mapStateToProps = state => ({
  loading: state.businessData.isRequestingConfirmation,
  confirmationMessage: state.businessData.confirmationMessage.message
});

const mapDispatchToProps = dispatch => ({
  confirmReservation: data => {
    dispatch(confirmReservation(data));
  },
  rejectReservation: data => {
    dispatch(rejectReservation(data));
  }
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(EmailConfirmation);
