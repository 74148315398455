import React from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  IconButton,
  Hidden,
  Drawer,
  CssBaseline,
  Avatar,
  MenuList,
  MenuItem,
  // ListItemIcon,
  ListItemText
} from "@material-ui/core";
import BusinessMenuItems from './BusinessMenuItems';
import ChainMenuItems from './ChainMenuItems';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';

const logo = require('../../../src/assets/images/white.png');

const drawerWidth = 240;
const locationsDrawerWidth = 300;
const styles = theme => ({
  termsMain: {
    position: "fixed",
    height: "100vh",
    zIndex: 1300,
    width: "100%",
    backgroundColor:"rgba(0,0,0,0.5)",
    scroll: null
  },
  displayNone:{
    display: "none"
  },
  displayBlock:{
    display: "block"
  },
  bottomSheet: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height:"200px",
    backgroundColor:"white",
    padding: "36px"
  },
  termsTitle: {
    fontFamily:'var(--secondary-normal-font)',
    fontSize:'28px',
    fontWeight: "400",
    lineHeight: "34px",
    maxWidth: "75%",
    display: 'flex'
  },
  termsBody: {
    fontFamily:'var(--primary-normal-font)',
    fontStyle: "normal",
    fontWeight: "450",
    fontSize: "12px",
    lineHeight: "24px",
    width: "75%",
    color: "#292929",
    opacity: 0.5,
    display: "flex"
  },
  termsLink: {
    fontFamily:'var(--primary-bold-font)',
    fontSize:'15px',
    lineHeight: "32px",
    padding: '20px 0px 6px 6px!important',
    display: 'flex',
    pointerevents: "none"
  },
  termsButtonDiv: {
    position: "absolute",
    right: "0",
    top: "40%",
    width:"25%"
  },
  termsButton: {
    fontFamily:'var(--primary-bold-font)',
    fontSize:'14px',
    lineHeight: "18px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    padding: '20px !important',
    display: 'flex',
    color: "white",
    backgroundColor: "#3A363D",
    borderRadius:"38px",
    margin:"auto"
  },
  root: {
    display: "flex"
  },
  button:{
    margin: theme.spacing.unit,
 },
 input: {
  display: 'none',
},
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  locationDrawer: {
    [theme.breakpoints.up("sm")]: {
      width: locationsDrawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    backgroundColor: "var(--primary-menu-color)"
  },
  appBarLocation: {
    marginLeft: locationsDrawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${locationsDrawerWidth}px)`
    },
    backgroundColor: "var(--primary-menu-color)"
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "var(--primary-menu-color)"
  },
  locationDrawerPaper: {
    width: locationsDrawerWidth,
    backgroundColor: "var(--primary-menu-color)"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    margin:'0 75px' ,
    overflowX: 'auto'
  },
  logo:{
    width:"115px"
  },
  bigAvatar: {
    margin: 22,
    width: 120,
    height: 120,
  },
  sidebarHeader:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center'
  },
  businessName:{
    fontFamily:'var(--secondary-normal-font)',
    fontSize:'22px',
    color:'var(--secondary-text-color)',
    padding: '0px 0px 6px 0px!important',
    textAlign:'center'
  },
  clickableGoBackButton:{
    fontFamily:'var(--primary-bold-font)',
    fontSize:'12px',
    color:'var(--secondary-text-color)',
    paddingBottom: '12px',
    cursor:'pointer',
    textAlign:'center',
    letterSpacing: '1.5px',
    opacity: 0.4
  },
  icon:{
    marginRight:0
  },
  extraButtons:{
    bottom: '24px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    width:"100%"
  },
  extraButton:{
    textAlign:'center',
    justifyContent:'flex-start',
    color:'var(--secondary-text-color)',
    textTransform: 'capitalize',
    fontFamily:'var(--primary-medium-font)',
    fontSize:'16px',
    paddingLeft:'1.5rem'
  },
  dividerLine:{
    backgroundColor:'var(--secondary-menu-color)',
    opacity: 0.4,
    margin:'18px 0px'
  },
  termsCloseButton:{
    position: "absolute",
    right: 20,
    top: 10,
    opacity: 0.5
  }
});


const TermsPopover = ({classes, termsAccepted, acceptTermsHandler, openTermsInNewTab, closePopout }) => (
  <div className={classes.termsMain} id={"TEST123"}>
    
    <div className={classes.bottomSheet}>
      <div>
        <Button className={classes.termsCloseButton} onClick={closePopout}> X </Button>
      </div>
      <div className={classes.termsTitle}>
        Please read and accept our updated our Terms & Conditions
      </div>
      <div className={classes.termsBody}>
        As a valued partner, we want you to know we’ve made updates to our <br/>
        Terms & Conditions, including additional payment verification methods for our Members.
      </div>
      {/*<div className={classes.termsLink}>*/}
      {/*  Read Updated Terms ›*/}
      {/*</div>*/}
      <div>
        <Button className={classes.termsLink} onClick={openTermsInNewTab}> Read Updated Terms ›</Button>
      </div>
      <div className={classes.termsButtonDiv}>
        <div>
          <Button className={classes.termsButton} onClick={acceptTermsHandler}> Accept Terms </Button>
        </div>
      </div>
    </div>
  
  </div>
);

const BusinessMenuDrawer = ({domain_type,classes,image,name, onChangeMenuHandle,showContactModal,allLocations,logOutHandler,handleUpload, openTermsInNewTab}) => (
  <div>
    <div className={classes.toolbar, "min-height-32px"} />
    <div className={classes.sidebarHeader}>
      <img className={classes.logo} src={logo}/>
      {/* <input
        accept="image/*"
        className={classes.input}
        id="text-button-file"
        multiple
        type="file"
        onChange={handleUpload}
      />
      <label htmlFor="text-button-file">
        <Button component="span" className={classes.button}> */}
        <Avatar alt={name} src={image} className={classes.bigAvatar} onClick={() => { domain_type === "Chain" && onChangeMenuHandle(1)}} />
        {/* </Button>
        </label> */}
    </div>
    <div className={ classes.businessName } >
      { name }
    </div>
    <div 
        className={domain_type === "Chain" ? classes.clickableGoBackButton : "" } 
        onClick={() => { domain_type === "Chain" && onChangeMenuHandle(1)}}>
      { domain_type === "Chain" ?  "GO BACK" : ""}
    </div>
    <Divider className={classes.dividerLine}/>
    <MenuList>
     <BusinessMenuItems allLocations={allLocations}/>
    </MenuList>
    <Divider className={classes.dividerLine}/>
     <div className={classes.extraButtons}>
      <Button className={classes.extraButton} disableRipple={true} className={classes.extraButton} onClick={showContactModal}>Contact Us</Button>
      <Button className={classes.extraButton} disableRipple={true} className={classes.extraButton} onClick={openTermsInNewTab}>Terms</Button>
      <Button className={classes.extraButton} disableRipple={true} className={classes.extraButton} onClick={logOutHandler}>Log out</Button>
     </div>
  </div>
);

const ChainMenuDrawer = ({classes,businesses,selectBusiness, name, image, showContactModal, onChangeMenuHandle,handleUpload, logOutHandler}) => ( 
  <div>
    <div className={classes.toolbar, "min-height-32px"}/>
    <div className={classes.sidebarHeader}>
      <img className={classes.logo} src={logo}/>
      {/* <input
        accept="image/*"
        className={classes.input}
        id="text-button-file"
        multiple
        type="file"
        onChange={handleUpload}
      />
      <label htmlFor="text-button-file">
        <Button component="span" className={classes.button}> */}
        <Avatar alt={name} src={image} className={classes.bigAvatar} />
        {/* </Button>
        </label> */}
    </div>
    <MenuList>
      <MenuItem>
        <ListItemText classes={{ primary: classes.businessName }}  primary={name} />
      </MenuItem>
      <Divider className={classes.dividerLine}/>
     <ChainMenuItems businessesList={businesses} selectBusiness={selectBusiness} onChangeMenuHandle={onChangeMenuHandle}/>
    </MenuList>
    <Divider className={classes.dividerLine}/>
    <div className={classes.extraButtons}>
      <Button className={classes.extraButton} disableRipple={true} className={classes.extraButton} onClick={showContactModal}>Contact Us</Button>
      <Button className={classes.extraButton} disableRipple={true} className={classes.extraButton} onClick={logOutHandler}>Log out</Button>
     </div>
  </div>
)
class Layout extends React.Component {
  state = {
    mobileOpen: false,
    menuValue:1,
    allLocations:false,
    showTermsPopout: !(this.props.businessData.merchantTermsAccepted)
  };
  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  openTermsInNewTab = () => {
    window.gtag("event", "terms_clicked")
    const url = window.location.hostname === "seated-merchant.firebaseapp.com" || window.location.hostname === "seatedrestaurants.io" || window.location.hostname === "www.seatedrestaurants.io"
      ? "https://seatedapp.io/restaurant-terms" :
      window.location.hostname === 'seated-merchant-staging.firebaseapp.com' || window.location.hostname === 'seated-merchant-staging.web.app' ?
        "https://staging.seatedapp.io/restaurant-terms" :
        "https://dev.seatedapp.io/restaurant-terms";
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}
  
  onChangeMenuHandle = (value, allLocations=false) => {
      this.setState({ menuValue:value })
      this.props.history.push(`/chain/${this.props.businessData.selectedChain}/home`)
      this.props.setSelectedBusiness(null)
      this.setState({allLocations:allLocations})
  }
  
  closeTermsPopout = () => {
    this.setState({ showTermsPopout: false })
  }
  
  selectBusiness = (id) => {
    this.props.setSelectedBusiness(id)
    this.setState({ menuValue:0 })
    this.props.history.push(`/business/${id}/home`)
   
  }
  acceptTerms = () => {
    console.log("Terms Accepted!");
    this.setState({ showTermsPopout: false });
    this.props.updateBusinessAccounts();
  }
  componentDidMount() {
    if(this.props.unauthorized_code){
      this.logOutHandler()
    }else {
      this.props.getBusinessAccounts();
      if(this.props.businessData.business.domain_type === "Chain"){
        if(this.props.location.pathname.indexOf('business') > -1) {
          this.setState({ menuValue:0 })
        }else {
          this.setState({ menuValue:1 })
          this.menuCheck(this.state.menuValue)
        }
      }else {
        // this.props.history.push(`/business/${this.props.businessData.selectedBusiness}/reservations`)
        this.setState({menuValue:0})
      }
    }
  }

  logOutHandler = () => {
    window.gtag("event", "Logged_out")
    window.localStorage.removeItem("auth_token");
    window.localStorage.removeItem("domain_type");
    window.localStorage.removeItem("domain");
    window.localStorage.removeItem("business_account_details");
    window.localStorage.removeItem("user_properties");
    window.localStorage.removeItem("master_login");

    this.props.history.push('/login')
    this.props.setSelectedBusiness(null)
    this.props.setSelectedChain(null)
    this.props.clearMessage()
   }
  menuCheck = (menuValue) => {
    if(menuValue === 1){
      this.props.history.push(`/chain/${this.props.businessData.selectedChain}/home`)
      this.onChangeMenuHandle(0,true)
    }
  }
  
  render() {
    const {
      classes,
      theme,
      location: { pathname },
      children
    } = this.props;
    const renderTermsPopover = () => {
      return <TermsPopover
        classes={classes}
        termsAccepted={false}
        acceptTermsHandler={this.acceptTerms}
        openTermsInNewTab={this.openTermsInNewTab}
        closePopout={this.closeTermsPopout}
      />
    }
    const renderMenus = () => {
        if(this.props.businessData.business.domain_type === "Chain"){
          let selectedBusiness = this.props.businessData.selectedBusiness
          let business_previews = this.props.businessData.business.domain.business_previews
          
       
          
          if(this.state.menuValue === 0){
            let business_id = this.props.location.pathname.match(/\d+/g).map(Number)[0];
            return <BusinessMenuDrawer
                  handleUpload={this.props.handleUpload} 
                  domain_type={this.props.businessData.business.domain_type}
                  classes={classes}
                  name={this.state.allLocations ? "All locations" : business_previews.filter(b=>b.id === business_id)[0].name} 
                  image={ this.props.businessData.businessImage && this.props.businessData.businessImage.style.large}
                  onChangeMenuHandle={this.onChangeMenuHandle}
                  showContactModal={this.props.showContactModal}
                  openTermsInNewTab={this.openTermsInNewTab}
                  allLocations={this.state.allLocations}
                  logOutHandler={this.logOutHandler}
                  />
          }else { 
            return <ChainMenuDrawer 
                    handleUpload={this.props.handleUpload} 
                    classes={classes} 
                    businesses={this.props.businessData.business.domain} 
                    name={this.props.businessData.business.domain.name} 
                    selectBusiness={this.selectBusiness}
                    onChangeMenuHandle={this.onChangeMenuHandle}
                    image={ this.props.businessData.businessImage && this.props.businessData.businessImage.style.large}
                    showContactModal={this.props.showContactModal}
                    logOutHandler={this.logOutHandler}
                    />
          }
        }else {
          return <BusinessMenuDrawer 
                  handleUpload={this.props.handleUpload} 
                  domain_type={this.props.businessData.business.domain_type}
                  classes={classes}
                  name={this.props.businessData.business.domain.name} 
                  image={this.props.businessData.businessImage && this.props.businessData.businessImage.style.large}
                  onChangeMenuHandle={this.onChangeMenuHandle}
                  showContactModal={this.props.showContactModal}
                  openTermsInNewTab={this.openTermsInNewTab}
                  logOutHandler={this.logOutHandler }
                  />
        }
    }
    return (
      <div className={classes.root}>
        
        <div className={(this.state.showTermsPopout && !this.props.businessData.merchantTermsAccepted)? classes.displayBlock : classes.displayNone}>
          { renderTermsPopover() }
        </div>
        {console.log("Business Data:",this.props.businessData)}
        <CssBaseline />
        <Hidden smUp implementation="css">
          <AppBar position="fixed" className={this.state.menuValue === 0 ? classes.appBar : classes.appBarLocation}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <img className={classes.logo} src={logo}/>
            </Toolbar>
          </AppBar>
        </Hidden>
        <nav className={ this.state.menuValue === 0 ?  classes.drawer : classes.locationDrawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper:this.state.menuValue === 0 ? classes.drawerPaper : classes.locationDrawerPaper
              }}
            >
               { renderMenus() }
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: this.state.menuValue === 0 ? classes.drawerPaper : classes.locationDrawerPaper
              }}
              variant="permanent"
              open
            >
              { renderMenus() }
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          { children }
        </main>
      </div>
    );
  }
}





Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
  businessData:PropTypes.object.isRequired,
  termsAccepted:PropTypes.bool,
  setSelectedBusiness:PropTypes.func,
  clearMessage:PropTypes.func,
  getBusinessAccounts:PropTypes.func
};
export default  compose(
  withRouter,
  withStyles(styles, { withTheme: true }) 
)(Layout);
