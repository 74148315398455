import axios from 'axios';
export const SEATED_API = window.location.hostname === "seated-merchant.firebaseapp.com" || window.location.hostname === "seated-merchant.web.app" || window.location.hostname === "seatedrestaurants.io" || window.location.hostname === "www.seatedrestaurants.io"
                          ? "https://merchant-portal.seatedapp.io" :
                          window.location.hostname === 'seated-merchant-staging.firebaseapp.com' || window.location.hostname === 'seated-merchant-staging.web.app' ?
                          "https://merchant-portal.staging.seatedapp.io" :
                          "https://merchant-portal.uat.seatedapp.io";

function* authRequest(data) {
  try {
    const response = yield makeRequest({
      path: "v1/merchant-portal/login",
      method: "POST",
      body: {
        email: data.email,
        passwordDigest: data.password
      }
    });
    return yield response.json();
  } catch (e) {
    throw e.messages;
  }
}

function* getBusinessData() {
  try {
    const response = yield makeRequest({
      path: "v1/merchant-portal/business/business_account_sessions",
      method: "GET"
    });
    return yield response.json();
  } catch (e) {
    if(e === 401){
      let error = { "messages":[{"text":"You are not authorized","code":"unauthorized","extra_data":null}], "success" : false}
      throw error.messages
    }
    throw  e.messages;
  }
}

function* dashboardRequest(data) {
  try {
    const response = yield makeRequest({
      path: "business/business_statistics/get_general_stats",
      method: "GET",
      params: data
    });
    return yield response.json();
  } catch (e) {
    throw e
  }
}

function* reviewsRequest(data) {
  try {
    const response = yield makeRequest({
      path: "v1/merchant-portal/business/business_reviews",
      method: "GET",
      params: data
    });
    return yield response.json();
  } catch (e) {
    throw e;
  }
}
function* getbusinessDetails(businessId) {
  try {
    const response = yield makeRequest({
      path: "v1/merchant-portal/business/businesses",
      method: "GET",
      params:{business_id:businessId}
    });
    return yield response.json();
  } catch (e) {
    throw e;
  }
}

function* getBusinessScheduleRanges(businessId) {
  try {
    const response = yield makeRequest({
      path: "v1/merchant-portal/business/business_schedule_ranges",
      method: "GET",
      params:{business_id:businessId}
    });
    return yield response.json();
  } catch (e) {
    throw e
  }
}

function* getReservations(data) {
  try {
    const response = yield makeRequest({
      path: "v1/merchant-portal/business/reservations",
      method: "GET",
      params: data
    });
    return yield response.json();
  } catch (e) {
    throw e
  }
}

function* getStatements(data) {
  try {
    const response = yield makeRequest({
      path: "business/billing_records",
      method: "GET",
      params: data
    });
    return yield response.json();
  } catch (e) {
    throw e
  }
}

function* statementsXls(data) {
  let params={...data, auth_token:window.localStorage.getItem("auth_token")};
  console.log(params)
  try {
  yield window.open(paramsObjectToUrl(`${SEATED_API}/business/billing_records.xlsx`,params));
  return;
  } catch (e) {
    throw e;
  }
}

function* getBillPayments(data){
  try {
    const response = yield makeRequest({
      path:`business/billing_transactions`,
      method:"GET",
      params:data
    })
    return yield response.json()
  } catch (error) {
    throw error
  }
}


function* getBusinessEmails(businessId) { 
 
  try {
    const response = yield makeRequest({
      path: "v1/merchant-portal/business/business_emails",
      method: "GET",
      params:{business_id:businessId}
    })
    return yield response.json()
  }catch(e){
    throw e
  }
}
function* getBusinessAccounts(businessId) { 
  try {
    const response = yield makeRequest({
      path: "v1/merchant-portal/business/business_accounts",
      method: "GET",
      params:{business_id:businessId}
    })
    return yield response.json()
  }catch(e){
    throw e
  }
}

function* postBusinessAccounts(data) { 
 
  try {
    const response = yield makeRequest({
      path: "v1/merchant-portal/business/business_accounts",
      method: "POST",
      body: data
      
    })
    return yield response.json()
  }catch(e){
    throw  e.messages;
  }
}
function* deleteBusinessAccounts(id) { 
 
  try {
    const response = yield makeRequest({
      path: `v1/merchant-portal/business/business_accounts/${id}`,
      method: "DELETE",
      
    })
    return yield response.json()
  }catch(e){
    throw  e.messages;
  }
}
function* updateBusinessAccounts(action) { 
 
  try {
    const response = yield makeRequest({
      path: `v1/merchant-portal/business/business_accounts/${action.id}`,
      method: "PUT",
      body: action.payload
      
    })
    return yield response.json()
  }catch(e){
    throw  e.messages;
  }
}

function* postBusinessEmails(data) { 
 
  try {
    const response = yield makeRequest({
      path: "v1/merchant-portal/business/business_emails",
      method: "POST",
      body: data,
      
    })
    return yield response.json()
  }catch(e){
    throw  e;
  }
}
function* deleteBusinessEmails(id) { 
 
  try {
    const response = yield makeRequest({
      path: `v1/merchant-portal/business/business_emails/${id}`,
      method: "DELETE",
      
    })
    return yield response.json()
  }catch(e){
    throw  e.messages;
  }
}
function* updateBusinessEmails(action) { 
 console.log(action)
  try {
    const response = yield makeRequest({
      path: `v1/merchant-portal/business/business_emails/${action.id}`,
      method: "PUT",
      body: action.payload
      
    })
    return yield response.json()
  }catch(e){
    throw  e.messages;
  }
}

function* createBusinessInfoRequest(data) { 
  try {
    const response = yield makeRequest({
      path: "v1/merchant-portal/business/business_info_change_requests",
      method: "POST",
      body: data
      
    })
    return yield response.json()
  }catch(e){
    throw e
  }
}

function* unsubscribeFromEmails(data){
  try {
    const response = yield makeRequest({
      path: "user/users/unsubscribe_from_emails",
      method: "POST",
      body: data
      
    })
    return yield response.json()
  }catch(e){
    throw e
  }
}
function* confirmReservation(data){
  try {
    const response = yield makeRequest({
      path: `business/reservations/confirm_reservation?rid=${data.token}`,
      method: "GET",
    })
    return yield response.json()
  }catch(e){
    throw  e.messages;
  }
}
function* rejectReservation(data){
  try {
    const response = yield makeRequest({
      path: `business/reservations/reject_reservation?rid=${data.token}`,
      method: "GET",
    })
    return yield response.json()
  }catch(e){
    throw  e.messages;
  }
}
function* resetPassword(action){
  console.log(action)
  try{
    const response = yield makeRequest({
      path:"v1/merchant-portal/business/password_resets",
      method:"POST",
      body:action
    })
    return yield response.json()
  }catch(e){
    throw e
  }
}

function* updatePassword(action){
  console.log(action)
  try{
    const response =yield makeRequest({
      path:"v1/merchant-portal/business/password_resets/update_password",
      method:"PUT",
      body:action
    })
    return yield response.json()
  }catch(e){
    throw e
  }
}


function* uploadBusinessImageRequest(action){
  console.log(action)
  try{
    const response = yield axios.post('https://seatedapp-old.uat.seatedapp.io/business/images',action, { headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("auth_token")
    }})
    return yield response
  }catch(e){
    throw e
  }
}


function paramsObjectToUrl(url, params){
  let Url = new URL(url);
  if (!params) return Url;
  Object.keys(params).forEach(key => {
    if(params[key]!==null){
      return  Url.searchParams.append(key, params[key])
    }});
  return Url;
}

export function makeRequest({ path, method, params, body }) {
  let url = new URL(`${SEATED_API}/${path}`);
  const token = window.localStorage.getItem("auth_token");
  
  if (params)
    Object.keys(params).forEach(key => {
      if(params[key]!==null){
        return  url.searchParams.append(key, params[key])
      }
     }
    );
  return fetch(url, {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token
    },
    body: JSON.stringify(body)
  }).then(res => {
      return errorHandler(res)
    })
}

function errorHandler(response) { 
  return new Promise((resolve,reject)=>{
      if(response.status === 200 || response.status === 201){
        resolve(response) 
      } else if(response.status === 401){
        response.json().then(r => {
          reject(response.status)
          window.localStorage.removeItem("auth_token")
          window.location.reload()
        }).catch((e)=>console.log(e))
      }
      else {
        response.json().then(r=>{
          reject(r)
        }).catch((e)=>console.log(e))
      }
  })
}
export const Api = {
  authRequest,
  getBusinessData,
  dashboardRequest,
  reviewsRequest,
  getReservations,
  getbusinessDetails,
  getBusinessScheduleRanges,
  getBusinessEmails,
  getBusinessAccounts,
  getStatements,
  postBusinessAccounts,
  updateBusinessAccounts,
  deleteBusinessAccounts,
  postBusinessEmails,
  updateBusinessEmails,
  deleteBusinessEmails,
  createBusinessInfoRequest,
  resetPassword,
  updatePassword,
  uploadBusinessImageRequest,
  statementsXls,
  getBillPayments: getBillPayments,
  unsubscribeFromEmails,
  confirmReservation,
  rejectReservation
};
