import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { withStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import moment from "moment";
import "./datepicker.css";

const styles = theme => ({
  root: {
    display: "flex"
  },
  paper: {
    marginRight: theme.spacing.unit * 2
  },
  select: {
    border: "1px solid var(--primary-border-color)",
    borderRadius: "4px",
    width: "214px",
    textTransform: "none",
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    fontSize: "15px",
    fontFamily: "var(--primary-normal-font)",
    letterSpacing: "0.5px"
  },
  chevronDown: {
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderWidth: "4px 4px 0 4px",
    borderColor: "var(--tertiary-button-color) transparent transparent transparent"
  },
  chevronLeft: {
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderWidth: "4px 4px 4px 0",
    borderColor: "transparent var(--tertiary-button-color)  transparent transparent"
  },
  dropList: {
    boxShadow: "none",
    width: "214px",
    border: "1px solid var(--primary-border-color)",
    borderRadius: 0
  }
});

class DropDown extends React.Component {
  state = {
    open: false,
    selectedOption: this.props.items[this.props.selectedOptionNumber ? this.props.selectedOptionNumber : 0],
    startDate: null,
    endDate: null
  };

  $moment = moment;
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  onSelect = (item, event) => {
    this.setState({ selectedOption: item })
    this.handleClose(event);
    var options = {}
    this.props.handleSelect(item, options);
    this.setState({
      startDate: null,
      endDate: null
    })
    console.log(this.state)
  };
  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleChangeStart = (date) => {
    this.setState({
      startDate: date
    }, this.checkState);
    if (!this.state.endDate) {
      this.toDatePicker.setOpen(true);
    }
  }
  handleChangeEnd = (date) => {
    this.setState({
      endDate: date
    }, this.checkState);
  }
  checkState() {
    console.log('here', this.state);
    if (this.state.startDate && this.state.endDate) {
      var options = { start_time: this.state.startDate, end_time: this.state.endDate }
      // var string = ""
      this.props.handleSelect("custom_range", options)
      this.setState({ selectedOption: [this.$moment(this.state.startDate).format('MM/DD/YY'), " - ", this.$moment(this.state.endDate).format('MM/DD/YY')] })
    }
  }

  toDatePicker;

  render() {
    const future = this.props.future;
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.root}>
        <div>
          <Button
            disableRipple={true}
            className={classes.select}
            buttonRef={node => {
              this.anchorEl = node;
            }}
            aria-owns={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
          >
            <div>{this.state.selectedOption}</div>
            <div
              className={!open ? classes.chevronDown : classes.chevronLeft}
            />
          </Button>
          <Popper open={open} anchorEl={this.anchorEl} transition disablePortal className="z-index-1">
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                className={classes.dropList}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>
                      {this.props.items.map((item, i) => {
                        return (
                          <MenuItem
                            className="primary-normal-font font-size-15 letter-spacing-0-5"
                            key={i}
                            onClick={event => this.onSelect(item, event)}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                      <MenuItem className="primary-normal-font font-size-15 letter-spacing-0-5" disabled={true}>Custom</MenuItem>
                      <MenuItem className="overflow-visible">
                        <div className='menu-datepicker-container'>
                          <DatePicker
                            selected={this.state.startDate}
                            selectsStart
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeStart}
                            filterDate={future === false ? (date) => {
                              return moment() > date;
                            } : (date) => {
                              return moment() < date;
                            }}
                          />
                          <span className="margin-top-bottom-0px margin-left-right-5px">-</span>
                          <DatePicker
                            ref={el => this.toDatePicker = el}
                            selected={this.state.endDate}
                            selectsEnd
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeEnd}
                            filterDate={future === false ? (date) => {
                              return moment() > date;
                            } : (date) => {
                              return moment() < date;
                            }}
                          />
                        </div>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

DropDown.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSelect: PropTypes.func,
  items: PropTypes.array,
  selectedOptionNumber: PropTypes.number 
};

export default withStyles(styles)(DropDown);
