import React from "react";
import moment from "moment";
// Import React Table
import ReactTable from "react-table";
import "./table.css";
import Pagination from "./pagination";

const pdfIcon = require('../../../src/assets/icons/pdf.png');
const getDate = date_string => {
  var timeZoneOffset = moment(date_string)._tzm;
  return moment(date_string)
    .utcOffset(0)
    .format("MM/DD/YY");
};

class PaymentsTable extends React.Component {

  getTime = date_string => {
    var timeZoneOffset = moment(date_string)._tzm;
    return moment(date_string)
      .utcOffset(timeZoneOffset)
      .format("hh:mmA");
  };

  checkPaymentMethod (type) {
    switch (type.value) {
      case "BANK_ACCOUNT":
        return "ACH"
      case "CREDIT_CARD":
        return "Credit Card"
      case "STRIPE":
        return "Stripe"
      case "MANUAL":
        return "ACH"
      case "CREDIT":
        return "Seated Credit"
      default:
        return type.value
    }
  }

  checkProductType (type) {
    switch (type.value) {
      case "DINE_IN_BILLING_INVOICE":
        return "Dine-in"
      case "SAH_BILLING_INVOICE":
        return "Seated at Home"
      default:
        return type.value
    }
  }

  fetchInvoicePdfLink(invoicePdf) {
    if (invoicePdf.original.hasOwnProperty('invoiceURL') && invoicePdf.value != '') {
      return <a href={ invoicePdf.value} target="_blank"><img src={pdfIcon}  Style="height: 40px" alt="invoice pdf"/></a> 
    }else{
      return ''
    }
  }

  onFetchData(state) {
    this.props.handleRequest(state);
  }
  render() {
    let totalCount = this.props.data.metaData ? this.props.data.metaData.totalCount : 1;
    return (
      <div>
        <ReactTable
          manual
          resizable={false}
          onFetchData={(state, instalnce) => this.onFetchData(state)}
          pages={Math.ceil(totalCount / 10)}
          data={this.props.data.objects}
          loading={this.props.loading}
          columns={[
            {
              Header: "Invoice Date",
              accessor: "invoiceDate",
              className: "primary-normal-font font-size-15 primary-text-color",
              Cell: row => getDate(row.value, "MM/DD/YY"),
              sortable: false
            },
            {
              Header: "Payment Method",
              accessor: "paymentMethod",
              className: "primary-normal-font font-size-15 primary-text-color",
              Cell: row => row.value
            },
            {
              Header: "Amount Paid",
              headerClassName: "amount_header",
              accessor: "amount",
              className: "primary-normal-font font-size-15 primary-text-color",
              Cell: row => {
                return <div className="amount_column">{"$" + row.value}</div>
              }
            },
            {
              Header: "Charged Date",
              accessor: "createdAt",
              className: "charged_date primary-normal-font font-size-15 primary-text-color",
              headerClassName: "text-align-left",
              Cell: row => getDate(row.value, "MM/DD/YY")
            },
            {
              Header: "Invoice PDF",
              sortable: false,
              accessor: "invoiceURL",
              headerClassName: "pdf_invoice_header",
              className: "pdf_invoice",
              Cell: (row) => this.fetchInvoicePdfLink(row)
            }
          ]}
          PaginationComponent={props => <Pagination {...props} />}
          defaultPageSize={10}
          defaultSorted={[
            {
              id: "createdAt",
              desc: false
            }
          ]}
          className="-striped "
        />
        <br />
      </div>
    );
  }
}

export default PaymentsTable;
