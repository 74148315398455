import React from "react";
import PropTypes from "prop-types";
import {
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import Routes from '../../Routes/routes';


const styles = theme => ({
    menuItem: {
      padding:'3px 0px 3px 23px',
      color: "var(--secondary-text-color)",
      height:"32px",
      '&:hover': {
        background:'linear-gradient(to right, var(--primary-menu-color) 0%, var(--primary-menu-color) 0%, var(--secondary-menu-color) 94%)!important'
    },
  },
    menuIcon: {
        width:'16px',
        marginRight:'20px'
    },
    selected:{
      background:'linear-gradient(to right, var(--primary-menu-color) 0%, var(--primary-menu-color) 0%, var(--secondary-menu-color) 94%)!important'
    }
  });
class BusinessMenuItems extends React.PureComponent{
    render(){
        const {
            allLocations,
            classes,
            location: { pathname },
          } = this.props;
          
        return ( 
           <div>
                { 
                  allLocations ? Routes.filter(route=> route.allLocations === true).map(route=>{
                    return <MenuItem
                    key={route.path}
                    className={classes.menuItem}
                    component={Link}
                    classes={{ selected: classes.selected }}
                    to={route.path}
                    selected={route.path === pathname.split("/")[3]}
                  >
                   <img className={classes.menuIcon} src={route.icon} alt=""/> 
                   <div className="primary-medium-font">{route.sidebarName}</div>
                  </MenuItem>
                }) :
                  Routes.map(route=>{
                        return <MenuItem
                        key={route.path}
                        className={classes.menuItem}
                        component={Link}
                        classes={{ selected: classes.selected }}
                        to={`${route.path}`}
                        selected={route.path === pathname.split("/")[3]}
                      >
                       <img className={classes.menuIcon} src={route.icon} alt=""/>
                       <div className="primary-medium-font">{route.sidebarName}</div>
                      </MenuItem>
                    })
                }
           </div>
           
        )
    
    }
}
  

export default compose(
    withRouter,
    withStyles(styles, { withTheme: true })
  )(BusinessMenuItems);