import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { unsubscribe } from "../../actions/businessDataActions";

import queryString from "query-string";
const styles = ({
    root:{
        padding:'24px',
        height:'220px',
        display:'flex',
        justifyContent:'space-between',
        flexDirection:'column'
    }
})
function Unsubscribe(props) {
  const { classes } = props 
  document.title = 'Seated App'
 const handleUnsubscribe = () => {
    const values = queryString.parse(props.location.search);
    console.log(values)
      props.unsubscribe(values.token)
  }

  return (
    <div className="width-50 margin-auto height-100vh display-flex flex-direction-column justify-content-center"
    >
      <Paper className={classes.root}>
        <Typography variant="h6" align="center">
         Update your E-mail Preference
        </Typography>
        <Typography component="p" align="center">
          Are you sure you want to unsubscribe? We would hate for you to miss out on our new restaurants, product updates, and promos!
        </Typography>
        <Button
          onClick={handleUnsubscribe}
          className="primary-button-background-color primary-medium-font secondary-text-color border-radius-0px padding-top-bottom-8px padding-right-left-24px display-flex justify-content-center margin-12px width-100"
        >
          Unsubscribe
        </Button>
      </Paper>
    </div>
  );
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
    unsubscribe: data => {
        dispatch(unsubscribe(data));
      },
})


  export default compose(connect(mapStateToProps,mapDispatchToProps), withStyles(styles))(Unsubscribe);