import React from "react";
import moment from "moment";

// Import React Table
import ReactTable from "react-table";
import "./table.css";
import Pagination from "./pagination";
import Grid from "@material-ui/core/Grid";
import ShowImage from "../ShowImage/ShowImage";

const ExpandedComponent = ({ row }) => {
  let orderAllDetails = row.original
  window.gtag("event", "history_order_opened", orderAllDetails)
  const receiptImages = row.original.receiptImages
  
  return (
    <Grid container style={{ padding: "18px 14px", backgroundColor: "#fff" }}>
      <Grid item xs={9}>
        <Grid container>
          <Grid item xs={6} style={{ padding: "14px 0px" }}>
            Name: {row.original.customerName}
          </Grid>
          <Grid item xs={6} style={{ padding: "14px 0px" }}>
            Order spend (pre-tax and pre-tip): {row.original.totalPreTipSpendAmount && `$${parseFloat(row.original.totalPreTipSpendAmount).toFixed(2)}`}
          </Grid>
          <Grid item xs={6} style={{ padding: "14px 0px" }}>
            Order type: {row.original.orderType}
          </Grid>
          <Grid item xs={6} style={{ padding: "12px 0px" }}>
            Charged rate: {row.original.chargedRate * 100}% {row.original.chargedRate == 0 && '(not billable)'}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={3}
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center"
        }}
      >
        {receiptImages &&
          <ShowImage images={receiptImages} rowDetails={row.original} orderTable={true}/>
        }
      </Grid>
    </Grid>
  );
};
class OrderHistoryTable extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  getTime = date_string => {
    return moment(date_string)
      .utcOffset(0)
      .format("hh:mmA")
      .toLowerCase();
  };
  getDate = date_string => {
    return moment(date_string)
      .utcOffset(0)
      .format("MM/DD/YY");
  };

  getUtcTime = date_string => {
    var timeZoneOffset = moment(date_string)._tzm;
    return moment(date_string)
      .utcOffset(timeZoneOffset)
      .format("hh:mmA")
      .toLowerCase();
  };
  getUtcDate = date_string => {
    var timeZoneOffset = moment(date_string)._tzm;
    return moment(date_string)
      .utcOffset(timeZoneOffset)
      .format("MM/DD/YY");
  };

  onFetchData(state) {
    this.props.handleRequest(state);
    this.setState({ state });
  }

  render() {
    let totalCount = this.props.data.metaData ? this.props.data.metaData.totalCount : 1;
    return (
      <div>
        <ReactTable
          manual
          resizable={false}
          sortable={false}
          pages={Math.ceil(totalCount / 10)}
          onFetchData={state => this.onFetchData(state)}
          data={this.props.data.objects}
          loading={this.props.loading}
          columns={[
            {
              columns: [
                {
                  Header: "Name",
                  accessor: "customerName"
                },
                {
                  Header: "Order Time",
                  accessor: "createdAt",

                  Cell: row =>
                    this.getDate(row.value) +
                    "-" +
                    this.getTime(row.value, "MM/DD/YY")
                },
                {
                  Header: "Invoice Date",
                  accessor: "invoicedAt",

                  Cell: row => {
                    console.log("invoice date")
                    console.log(row.original.isInvoiceExcluded)
                    console.log(row.value)
                    if (row.original.isInvoiceExcluded === true) {
                      return "Excluded from Invoice";
                    }
                    if (row.value) {
                      return this.getDate(row.value, "MM/DD/YY");
                    } else {
                      return <div style={{fontWeight: 600}}>-</div>;
                    }
                  }
                },
                {
                  Header: "Order Type",
                  accessor: "orderType"
                }
              ]
            },
            {
              columns: [
                {
                  expander: true,
                  Header: () => <div>More</div>,
                  width: 65,
                  Expander: ({ isExpanded, ...rest }) => (
                    <div>
                      {isExpanded ? (
                        <span>&#x229D;</span>
                      ) : (
                        <span>&#x2295;</span>
                      )}
                    </div>
                  ),
                  style: {
                    cursor: "pointer",
                    fontSize: 25,
                    padding: "0",
                    textAlign: "center",
                    userSelect: "none"
                  }
                }
              ]
            }
          ]}
          PaginationComponent={props => <Pagination {...props} />}
          page={
            this.state.page !== this.props.page
              ? this.props.page
              : this.state.page
          }
          defaultPageSize={10}
          defaultSorted={[
            {
              id: "createdAt",
              desc: false
            }
          ]}
          className="-striped "
          SubComponent={row => <ExpandedComponent row={row} />}
        />
        <br />
      </div>
    );
  }
}

export default OrderHistoryTable;
