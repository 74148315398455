import { put, takeLatest } from "redux-saga/effects";
import {
  dashboardRequestSuccess,
  reviewsRequestSuccess,
  reviewsRequestFailed,
  getBusinessDataSuccess,
  getReservationsRequestSuccess,
  businessDetailsRequestSuccess,
  businessDetailsRequestFailed,
  businessScheduleRequestSuccess,
  businessScheduleRequestFailed,
  getBusinessEmailsRequest,
  getBusinessEmailsRequestSuccess,
  getBusinessEmailsRequestFailed,
  getBusinessAccountsRequest,
  getBusinessAccountsRequestSuccess,
  getBusinessAccountsRequestFailed,
  getStatementsRequestSuccess,
  postBusinessAccountsRequestSuccess,
  updateBusinessAccountsRequestSuccess,
  deleteBusinessEmailsRequestSuccess,
  postBusinessEmailsRequestSuccess,
  updateBusinessEmailsRequestSuccess,
  deleteBusinessAccountsRequestSuccess,
  createBusinessInfoRequestSuccess,
  createBusinessInfoRequestFailed,
  resetPasswordRequestSuccess,
  resetPasswordRequestFailed,
  uploadBusinessImageRequestSuccess,
  uploadBusinessImageRequestFailed,
  getBillPaymentsRequestSuccess,
  getUnpaidBalanceRequest,
  getUnpaidBalanceRequestSuccess,
  getUnpaidBalanceRequestFailed,
  unsubscribeSuccess,
  updatePasswordRequestSuccess,
  updatePasswordRequestFailed, getBusinessAccountsSuccessV2, updateBusinessAccountsV2Success
} from "../actions/businessDataActions";

import { resetIsAuthorized } from "../actions/auth"

import FileSaver from 'file-saver';
import * as actionTypes from "../actions/actionTypes";
import { Api } from "./Api";
import { ApiV2 } from "./ApiV2";
import {GET_BUSINESS_ACCOUNTS_V2} from "../actions/actionTypes";

function* getBusinessData() {
  try {
    const result = yield Api.getBusinessData();
    yield put(getBusinessDataSuccess(result));
  } catch (e) {
    let code = typeof e === "undefined" ? "undefined" : e.find(e=>e).code 
    if(code === "unauthorized" ){
      put(resetIsAuthorized())
      yield put({
        type: actionTypes.GET_BUSINESS_DATA_REQUEST_FAILED,
        payload: {
          message: { message: e.find(r=> r).text ? e.find(r=> r).text : "Something went wrong" , variant: "error", code: code}
        }
      });
    }else {
      yield put({
        type: actionTypes.GET_BUSINESS_DATA_REQUEST_FAILED,
        payload: {
          message: { message: "Something went wrong" , variant: "error"}
        }
      });
    }
  }
}

function* dashboardRequestSaga(action) {
  try {
    const result = yield ApiV2.dashboardRequest(action.payload);

    yield put(dashboardRequestSuccess(result));
  } catch (e) {
    yield put({ type: actionTypes.DASHBOARD_DATA_REQUEST_FAILED });
  }
}

function* getBusinessAccountsV2Saga(action) {
  try {
    const result = yield ApiV2.getBusinessAccountsV2(action.payload);
    yield put(getBusinessAccountsSuccessV2(result));
  } catch (e) {
    yield put({ type: actionTypes.GET_BUSINESS_ACCOUNTS_FAILED_V2 });
  }
}

function* updateBusinessAccountsV2Saga(action) {
  try {
    const result = yield ApiV2.updateBusinessAccountsV2(action.payload);
    
    yield put(updateBusinessAccountsV2Success(result));
  } catch (e) {
    yield put({ type: actionTypes.UPDATE_BUSINESS_ACCOUNTS_FAILED_V2 });
  }
}
function* reviewsRequestSaga(action) {
  try {
    const result = yield Api.reviewsRequest(action.payload);
    yield put(reviewsRequestSuccess(result));
  } catch (e) {
    yield put(reviewsRequestFailed());
  }
}

function* getReservations(action) {
  try {
    const result = yield Api.getReservations(action.payload);
    yield put(getReservationsRequestSuccess(result));
  } catch (e) {
    yield put({ type: actionTypes.GET_RESERVATIONS_REQUEST_FAILED });
  }
}

function* getStatementsSaga(action) {
  try {
    const result = yield ApiV2.getStatements(action.payload);
    yield put(getStatementsRequestSuccess(result));
  } catch (e) {
    yield put({ type: actionTypes.GET_STATEMENTS_REQUEST_FAILED });
  }
}

function* statementsXlsSaga(action) {
  try {
    const result = yield ApiV2.statementsXls(action.payload);
    const fileName = 'SeatedStatements.xlsx';
    FileSaver.saveAs(result, fileName);
  } catch (e) {
    console.log(e);
  }
}

function* getBillPaymentsSaga(action) {
  try {
    const result = yield ApiV2.getBillPayments(action.payload);
    yield put(getBillPaymentsRequestSuccess(result));
  } catch (error) {
    console.log(error);
  }
}

function* getUnpaidBalanceSaga(action) {
  try {
    const result = yield ApiV2.getUnpaidBalance(action.payload);
    yield put(getUnpaidBalanceRequestSuccess(result));
  } catch (error) {
    yield put(getUnpaidBalanceRequestFailed());
  }
}

function* getBusinessDetails(action) {
  try {
    const result = yield Api.getbusinessDetails(action.payload);
    yield put(businessDetailsRequestSuccess(result));
  } catch (e) {
    yield put(businessDetailsRequestFailed());
  }
}

function* getBusinessScheduleRanges(action) {
  try {
    const result = yield Api.getBusinessScheduleRanges(action.payload);
    yield put(businessScheduleRequestSuccess(result));
  } catch (e) {
    yield put(businessScheduleRequestFailed());
  }
}

function* getBusinessEmailsSaga(action) {
  try {
    const result = yield Api.getBusinessEmails(action.payload);
    yield put(getBusinessEmailsRequestSuccess(result));
  } catch (e) {
    yield put(getBusinessEmailsRequestFailed());
  }
}
function* getBusinessAccountsSaga(action) {
  try {
    const result = yield Api.getBusinessAccounts(action.payload);
    yield put(getBusinessAccountsRequestSuccess(result));
  } catch (e) {
    yield put(getBusinessAccountsRequestFailed());
  }
}

function* postBusinessAccountsSaga(action) {
  try {
    yield Api.postBusinessAccounts(action.payload);
    yield put(postBusinessAccountsRequestSuccess());
    yield put(getBusinessAccountsRequest(action.businessId));
  } catch (e) {
    yield put({
      type: actionTypes.POST_BUSINESS_ACCOUNTS_FAILED,
      payload: { message: e[0].text, variant: "error" }
    });
  }
}

function* updateBusinessAccountsSaga(action) {
  try {
    yield Api.updateBusinessAccounts(action);
    yield put(updateBusinessAccountsRequestSuccess());
    yield put(getBusinessAccountsRequest(action.businessId));
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_BUSINESS_ACCOUNTS_FAILED,
      payload: { message: e[0].text, variant: "error" }
    });
  }
}

function* deleteBusinessAccountsSaga(action) {
  try {
    yield Api.deleteBusinessAccounts(action.payload);
    yield put(deleteBusinessAccountsRequestSuccess());
    yield put(getBusinessAccountsRequest(action.businessId));
  } catch (e) {
    yield put({
      type: actionTypes.DELETE_BUSINESS_ACCOUNTS_FAILED,
      payload: { message: e[0].text, variant: "error" }
    });
  }
}

function* postBusinessEmailsSaga(action) {
  try {
    yield Api.postBusinessEmails(action.payload);
    yield put(postBusinessEmailsRequestSuccess());
    yield put(getBusinessEmailsRequest(action.businessId));
  } catch (e) {
    console.log("MESSAGE", e.email[0]);
    yield put({
      type: actionTypes.POST_BUSINESS_EMAILS_FAILED,
      payload: { message: `Email ${e.email[0]}`, variant: "error" }
    });
  }
}

function* updateBusinessEmailsSaga(action) {
  try {
    yield Api.updateBusinessEmails(action);
    yield put(updateBusinessEmailsRequestSuccess());
    yield put(getBusinessEmailsRequest(action.businessId));
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_BUSINESS_EMAILS_FAILED,
      payload: { message: e[0].text, variant: "error" }
    });
  }
}
function* unsubscribeSaga(action) {
  console.log(action);
  try {
    const result = yield Api.unsubscribeFromEmails({
      email_unsubscribe_token: action.payload
    });
    yield put(unsubscribeSuccess(result.messages[0].text));
  } catch (e) {
    console.log(e);
  }
}
function* confirmReservationSaga(action) {
  yield put({ type: actionTypes.IS_REQUESTING_CONFIRMATION, payload: true });
  try {
    const result = yield Api.confirmReservation({ token: action.payload });
    yield put({
      type: actionTypes.CONFIRM_RESERVATION_SUCCESS,
      payload: { message: result.messages[0].text }
    });
    yield put({ type: actionTypes.IS_REQUESTING_CONFIRMATION, payload: false });
  } catch (e) {
    yield put({
      type: actionTypes.CONFIRM_RESERVATION_REQUEST_FAILED,
      payload: { message: e[0].text, variant: "error" }
    });
    yield put({ type: actionTypes.IS_REQUESTING_CONFIRMATION, payload: false });
  }
}
function* rejectReservationSaga(action) {
  yield put({ type: actionTypes.IS_REQUESTING_CONFIRMATION, payload: true });
  try {
    const result = yield Api.rejectReservation({ token: action.payload });
    yield put({
      type: actionTypes.REJECT_RESERVATION_SUCCESS,
      payload: { message: result.messages[0].text }
    });
    yield put({ type: actionTypes.IS_REQUESTING_CONFIRMATION, payload: false });
  } catch (e) {
    yield put({
      type: actionTypes.CONFIRM_RESERVATION_REQUEST_FAILED,
      payload: { message: e[0].text, variant: "error" }
    });
    yield put({ type: actionTypes.IS_REQUESTING_CONFIRMATION, payload: false });
  }
}
function* deleteBusinessEmailsSaga(action) {
  try {
    yield Api.deleteBusinessEmails(action.payload);
    yield put(deleteBusinessEmailsRequestSuccess());
    yield put(getBusinessEmailsRequest(action.businessId));
  } catch (e) {
    yield put({
      type: actionTypes.DELETE_BUSINESS_EMAILS_FAILED,
      payload: { message: e[0].text, variant: "error" }
    });
  }
}

function* createBusinessInfoRequestSaga(action) {
  try {
    yield Api.createBusinessInfoRequest(action.payload);
    yield put(createBusinessInfoRequestSuccess());
  } catch (e) {
    yield put(createBusinessInfoRequestFailed());
  }
}

function* resetPasswordSaga(action) {
  try {
    const result = yield Api.resetPassword(action.payload);
    console.log(result)
    let message = result.messages.find(r=>r.text).text
    yield put(resetPasswordRequestSuccess({message:message, variant:"success"}));
  } catch (e) {
    console.log(e);
    let errorMessage= e.customError.messages.find(r => r.text).text;

    yield put(resetPasswordRequestFailed({message:errorMessage, variant:'error'}));
  }
}

function* updatePasswordSaga(action) {
  try {
    const result = yield Api.updatePassword(action.payload);
    console.log(result)
    let message = result.messages.find(r=>r.text).text
    yield put(
      updatePasswordRequestSuccess({ message: message, variant: "success" })
    );
  } catch (e) {
    console.log(e);
    let errorMessage = e.customError.messages.find(r => r.text).text;
    yield put(
      updatePasswordRequestFailed({ message: errorMessage, variant: "error" })
    );
  }
}

function* uploadBusinessImageSaga(action) {
  try {
    const result = yield Api.uploadBusinessImageRequest(action.payload);
    yield put(uploadBusinessImageRequestSuccess(result.data));
  } catch (e) {
    console.log(e);
    yield put(uploadBusinessImageRequestFailed());
  }
}

export function* watchBusinessDataRequestSaga() {
  yield takeLatest(actionTypes.DASHBOARD_DATA_REQUEST, dashboardRequestSaga);
  yield takeLatest(actionTypes.GET_BUSINESS_ACCOUNTS_V2, getBusinessAccountsV2Saga);
  yield takeLatest(actionTypes.UPDATE_BUSINESS_ACCOUNTS_V2, updateBusinessAccountsV2Saga);
  yield takeLatest(actionTypes.GET_BUSINESS_DATA_REQUEST, getBusinessData);
  yield takeLatest(actionTypes.BUSINESS_REVIEWS_REQUEST, reviewsRequestSaga);
  yield takeLatest(actionTypes.GET_RESERVATIONS_REQUEST, getReservations);
  yield takeLatest(
    actionTypes.BUSINESS_SCHEDULE_REQUEST,
    getBusinessScheduleRanges
  );
  yield takeLatest(actionTypes.BUSINESS_DETAILS_REQUEST, getBusinessDetails);
  yield takeLatest(actionTypes.GET_BUSINESS_EMAILS, getBusinessEmailsSaga);
  yield takeLatest(actionTypes.GET_BUSINESS_ACCOUNTS, getBusinessAccountsSaga);
  yield takeLatest(
    actionTypes.POST_BUSINESS_ACCOUNTS,
    postBusinessAccountsSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_BUSINESS_ACCOUNTS,
    updateBusinessAccountsSaga
  );
  yield takeLatest(
    actionTypes.DELETE_BUSINESS_ACCOUNTS,
    deleteBusinessAccountsSaga
  );
  yield takeLatest(actionTypes.POST_BUSINESS_EMAILS, postBusinessEmailsSaga);
  yield takeLatest(
    actionTypes.UPDATE_BUSINESS_EMAILS,
    updateBusinessEmailsSaga
  );
  yield takeLatest(
    actionTypes.DELETE_BUSINESS_EMAILS,
    deleteBusinessEmailsSaga
  );
  yield takeLatest(actionTypes.GET_STATEMENTS_REQUEST, getStatementsSaga);
  yield takeLatest(
    actionTypes.CREATE_BUSINESS_INFO_REQUEST,
    createBusinessInfoRequestSaga
  );
  yield takeLatest(actionTypes.RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(actionTypes.UPDATE_PASSWORD_REQUEST, updatePasswordSaga);
  yield takeLatest(actionTypes.UPLOAD_BUSINESS_IMAGE, uploadBusinessImageSaga);
  yield takeLatest(actionTypes.STATEMENTS_XLS_REQUEST, statementsXlsSaga);
  yield takeLatest(
    actionTypes.GET_BILL_PAYMENTS_REQUEST,
    getBillPaymentsSaga
  );
  yield takeLatest(actionTypes.GET_UNPAID_BALANCE_REQUEST, getUnpaidBalanceSaga);
  yield takeLatest(actionTypes.UNSUBSCRIBE_FROM_EMAILS, unsubscribeSaga);
  yield takeLatest(
    actionTypes.CONFIRM_RESERVATION_REQUEST,
    confirmReservationSaga
  );
  yield takeLatest(
    actionTypes.REJECT_RESERVATION_REQUEST,
    rejectReservationSaga
  );
}
