import React from 'react'



const BoxView = ({title,number, hasDollars, subtitle}) => { 
    return ( 
        <div className="flex-wrap max-width-200px max-height-100px text-align-center">
        <div className="primary-text-color primary-normal-font font-size-24 align-self-center">{title}</div>
        <div className="primary-text-color secondary-normal-font font-size-56 align-self-center">{hasDollars===true?'$':''}{number ? number.toLocaleString('en') : number}</div>
        <div className="primary-text-color primary-normal-font font-size-18 align-self-center">{subtitle}</div>
        </div>
    )

}

export default BoxView