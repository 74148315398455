import React from "react";
import Button from "@material-ui/core/Button";
import "./userPopUp.css";

const EditUserForm = props => {
  let {
    isPasswordRequired,
    name,
    email,
    handleInputChange,
    invoices,
    confirmations,
    cancellations,
    admin,
    onSubmit,
    onDelete,
    open,
    password,
    isNewUser
  } = props;
  
  return (
    <form className="width-480px display-flex flex-direction-column justify-content-space-between align-items-center margin-top-10px margin-right-bottom-left-30px">
      <label className="text_input_label">
        NAME
        <input
          name="name"
          type="text"
          value={name}
          placeholder="e.g. John Appleseed"
          checked={name}
          onChange={handleInputChange}
          className="user_pop_up_input"
        />
      </label>
      <label className="text_input_label">
        EMAIL
        <input
          name="email"
          type="email"
          value={email}
          placeholder="e.g. john@appleseed.com"
          onChange={handleInputChange}
          className="user_pop_up_input"
        />
      </label>
      <label className="checkbox_input_label">
        EMAIL TYPE(S)
        <div className="checkbox_container">
          <label className="label" htmlFor={"invoices"}>
            <input
              name="Invoices"
              type="checkbox"
              id="invoices"
              checked={invoices}
              onChange={handleInputChange}
            />
            Invoices
          </label>
          <label className="label" htmlFor={"confirmations"}>
            <input
              name="Confirmations"
              type="checkbox"
              id="confirmations"
              checked={confirmations}
              onChange={handleInputChange}
            />
            Confirmations
          </label>
          <label className="label" htmlFor={"cancellations"}>
            <input
              name="Cancellations"
              type="checkbox"
              id="cancellations"
              checked={cancellations}
              onChange={handleInputChange}
            />
            Cancellations
          </label>
          <label className="label" htmlFor={"admin"}>
            <input
              name="Admin"
              type="checkbox"
              id="admin"
              checked={admin}
              onChange={handleInputChange}
            />
            Admin
          </label>
        </div>
      </label>
      {admin !== true ? (
        ""
      ) : (
        <div className="width-100">
          <label className="text_input_label">
            PASSWORD
            <input
              name="password"
              type="password"
              placeholder="New Password"
              defaultValue={password}
              onChange={handleInputChange}
              className="user_pop_up_input"
            />
          </label>
          <div className="width-75 float-right">
            {isPasswordRequired && (
              <div className="warning-text-color font-size-12 text-align-center">
                The password must be minimum 8 characters
              </div>
            )}
          </div>
        </div>
      )}

      <Button
        aria-owns={open ? "simple-popper" : undefined}
        aria-haspopup="true"
        variant="contained"
        onClick={onSubmit}
        className="primary-button-background-color width-40 secondary-text-color margin-top-30px align-self-center box-shadow-none border-radius-0px primary-medium-font"
      >
        {isNewUser ? "ADD" : "UPDATE"}
      </Button>
      <Button
        size="small"
        onClick={onDelete}
        className="secondary-button-background-color width-40 quaternary-text-color margin-top-10px align-self-center primary-medium-font"
      >
        {!isNewUser && " Remove user"}
      </Button>
    </form>
  );
};
export default EditUserForm;
