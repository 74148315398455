import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import DialogTitle from './DialogTitle/index'

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AppDialog extends React.Component {
  

  render() {
      let {title,buttonTitle,open, handleClose, children,dialogButton,emailButton=false, width="md"}=this.props

    return (
      <div>
        <Dialog
          maxWidth={width}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          className="modal_container"
        >
         <DialogTitle id="customized-dialog-title" onClose={handleClose}>
             {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description" className="text-align-center">
              {children}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="justify-content-center margin-top-bottom-16px margin-left-right-4px">
          { emailButton ?  <a className="text-decoration-none" href="mailto:partnerships@seatedapp.io">
            <Button onClick={dialogButton} className="primary-button-background-color primary-medium-font secondary-text-color align-self-center border-radius-0px padding-top-bottom-8px padding-right-left-24px">
              {buttonTitle}
            </Button>
           </a> :
            <Button onClick={dialogButton} className="primary-button-background-color primary-medium-font secondary-text-color align-self-center border-radius-0px padding-top-bottom-8px padding-right-left-24px">
              {buttonTitle}
            </Button>
        }
         
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AppDialog;