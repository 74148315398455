import React from "react";
import LoginForm from './loginForm'
import "./login.css";
const logo = require("../../assets/images/white.png");

const Login = () => { 
  return (
    <div id="authViewContainer">
      <div className="auth-view-background-container">
        <div className="auth-view-background">
          <div className="auth-view-background-black-layer" />
        </div>
      </div>
      <div className="auth-view-container vertical-auth-flex-container">
        <div className="auth-flex-item auth-logo-container">
          <div
            className="auth-logo"
            style={{ backgroundImage: `url(${logo})` }}
          />
          <div className="auth-logo-text is-g-medium">
            <span className="primary-medium-font">Restaurant Portal</span>
          </div>
        </div>
        {/* FORMS */}
        <div className="auth-flex-item">
          <div className="auth-forms-wrapper">
            {/* LOGIN FORM   */}
            <LoginForm/>
          </div>
        </div>
      </div>
    </div>
  );

}
   

export default Login


