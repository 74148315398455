import { all } from 'redux-saga/effects'
import { watchAuthRequestSaga } from './authSaga'
import { watchBusinessDataRequestSaga } from './businessDataSaga'

export default function* rootSaga() {
   yield all([
   watchAuthRequestSaga(),
   watchBusinessDataRequestSaga()
   ]);
}
