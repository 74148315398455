import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, Avatar } from "@material-ui/core";
const location_arrow = require("../../assets/icons/location_arrow_icon.png");

const styles = theme => ({
  menuItem: {
    paddingTop:'3px',
    paddingBottom:'3px',
    paddingLeft:'23px',
    color: "var(--secondary-text-color)",
    height: "62px",
    fontSize: "16px",
    display: "flex",
    whiteSpace: "normal",
    justifyContent: "space-between",
    "&:hover": {
      background:
        "linear-gradient(to left, var(--primary-menu-color) 0%, var(--primary-menu-color) 0%, var(--secondary-menu-color) 94%)!important"
    }
  },
  menuIcon: {
    width: "10px",
    marginRight: "20px"
  },
  selected: {
    background:
      "linear-gradient(to right, var(--primary-menu-color) 0%, var(--primary-menu-color) 0%, var(--secondary-menu-color) 94%)!important"
  },
  smallAvatar: {
    width: 60,
    height: 60,
    marginRight: 20
  },
  itemImageText: {
    display: "flex",
    alignItems: "center"
  }
});

const ChainMenuItems = ({
  businessesList,
  classes,
  selectBusiness,
  onChangeMenuHandle
}) => {
  return (
    <div>
      <MenuItem
        className={classes.menuItem}
        onClick={() => onChangeMenuHandle(0, true)}
      >
        <div className="text-align-center margin-left-32px">
          ALL LOCATIONS
        </div>
        <img className={classes.menuIcon} src={location_arrow} />
      </MenuItem>
      {businessesList.business_previews.map(b => {
        return (
          <MenuItem
            key={b.id}
            className={classes.menuItem}
            onClick={() => selectBusiness(b.id)}
          >
            <div className={classes.itemImageText}>
              {b.main_image && (
                <Avatar
                  src={b.main_image.style.thumb && b.main_image.style.thumb}
                  className={classes.smallAvatar}
                  alt={b.name}
                />
              )}
              <div className="primary-medium-font">{b.name}</div>
            </div>
            <img className={classes.menuIcon} src={location_arrow} />
          </MenuItem>
        );
      })}
    </div>
  );
};

export default withStyles(styles)(ChainMenuItems);
