import React from "react";
import moment from "moment";
import momentTimezone from 'moment-timezone';

// Import React Table
import ReactTable from "react-table";
import "./table.css";
import Pagination from "./pagination";
import Grid from "@material-ui/core/Grid";
import ShowImage from "../ShowImage/ShowImage";
const receipt_icon = require("../../assets/icons/receipt_icon.svg");
const card_icon = require("../../assets/icons/card_icon.svg");

const ExpandedComponent = ({ row }) => {
  let allReservationDetails = row.original
  window.gtag("event", "history_reservation_opened", allReservationDetails)
  const receiptImages = row.original.receiptImages
  const getDateinBusinessTimezone = (date_string, businessTimeZone = "America/New_York") => {
    return momentTimezone.utc(date_string).tz(businessTimeZone).format("MM/DD/YY");
  }
  const getTimeinBusinessTimezone = (date_string, businessTimeZone = "America/New_York") => {
    return momentTimezone.utc(date_string).tz(businessTimeZone).format("hh:mm A");
  }
  const getTimeinBusinessTimezoneWithTZ = (date_string, businessTimeZone = "America/New_York") => {
    return momentTimezone.utc(date_string).tz(businessTimeZone).format("hh:mm A z");
  }
  const getBookingType = historyObj => {
    if (historyObj.reservationId || historyObj.integration == "SEATED_API_PARTNER") {
      return "Seated Guest"
    } else {
      return "Network Guest"
    }
  }
  const getPartyDate = historyObj => {
    // show time stamp along with date if booking type is "Seated Guest" and booking type is non walk-in otherewise show just date
    return (getBookingType(historyObj) == "Seated Guest" && !historyObj.isWalkIn ) ? `${moment(historyObj.startTimeAsIfUtc).utcOffset(0).format("MM/DD/YY")} at ${moment(historyObj.startTimeAsIfUtc).utcOffset(0).format("hh:mm A")} ${(historyObj.businessTimeZone && historyObj.businessTimeZone != '') ? moment.tz(historyObj.startTimeAsIfUtc, historyObj.businessTimeZone).zoneAbbr() : ''}` : `${moment(historyObj.startTimeAsIfUtc).utcOffset(0).format("MM/DD/YY")}`
  }
  return (
    <Grid container style={{ padding: "18px 14px" }}>
      <Grid item xs={9}>
        <Grid container>
          {row.original.paymentType == "CARD" && row.original.paymentNetwork && row.original.creditCardLastFourDigits &&
          <Grid item xs={12} className="primary-normal-font font-size-15" style={{ padding: "14px 0px" }}>
            <span className="font-weight-bold">Payment | </span><span className="text-transform-capitalize">{row.original.paymentNetwork.toLowerCase()}</span> ending in {row.original.creditCardLastFourDigits}
          </Grid>
          }
          {row.original.paymentType != "CARD" && row.original.paymentType &&
          <Grid item xs={12} className="primary-normal-font font-size-15" style={{ padding: "14px 0px" }}>
            <span className="font-weight-bold">Payment | </span><span className="text-transform-capitalize">{row.original.paymentType.toLowerCase()}</span>
          </Grid>
          }
          {row.original.paymentType != "CARD" && row.original.checkNumber &&
          <Grid item xs={12} className="primary-normal-font font-size-15" style={{ padding: "14px 0px" }}>
            <span className="font-weight-bold">Check # | </span>{row.original.checkNumber}
          </Grid>
          }
          {
            <Grid item xs={12} className="primary-normal-font font-size-15" style={{ padding: "14px 0px" }}>
              <span className="font-weight-bold">Booking Details | </span><span className="text-transform-capitalize">{getBookingType(row.original) }</span>
            </Grid>
          }
          {row.original.startTimeAsIfUtc &&
            <Grid item xs={12} className="primary-normal-font font-size-15" style={{ padding: "14px 0px" }}>
              <span className="font-weight-bold">Party Date | </span> { getPartyDate(row.original) }
            </Grid>
          }
          {
          <Grid item xs={12} className="primary-normal-font font-size-15" style={{ padding: "12px 0px" }}>
            <span className="font-weight-bold">Charged Rate | </span>{Math.round(row.original.chargedRate * 100)}% {row.original.chargedRate == 0 && '(not billable)'}
          </Grid>
          }
          {row.original.paymentType == "CARD" && row.original.transactionType &&
          <Grid item xs={12} className="primary-normal-font font-size-15" style={{ padding: "14px 0px" }}>
            <span className="font-weight-bold">Transaction Type | </span><span className="text-transform-capitalize">{row.original.transactionType == "UNKNOWN_TRANSACTION_TYPE" ? "unknown" : row.original.transactionType.toLowerCase()}</span>
          </Grid>
          }
          {row.original.paymentType == "CARD" && row.original.transactionAmount > 0 &&
          <Grid item xs={12} className="primary-normal-font font-size-15" style={{ padding: "14px 0px" }}>
            <span className="font-weight-bold">Transaction Amount | </span>{`$${parseFloat(row.original.transactionAmount).toFixed(2)}`}
          </Grid>
          }
          {row.original.paymentType == "CARD" && row.original.transactionTimestamp &&
          <Grid item xs={12} className="primary-normal-font font-size-15" style={{ padding: "14px 0px" }}>
            <span className="font-weight-bold">Transaction Timestamp | </span>{`${getDateinBusinessTimezone(row.original.transactionTimestamp, row.original.businessTimeZone)}
              ${getTimeinBusinessTimezone(row.original.transactionTimestamp, row.original.businessTimeZone) != '12:00 AM' ? `at ${getTimeinBusinessTimezoneWithTZ(row.original.transactionTimestamp, row.original.businessTimeZone)}` : ''}`
            }
          </Grid>
          }
          {
          <Grid item xs={12} className="primary-normal-font font-size-15" style={{ padding: "12px 0px" }}>
            <span className="font-weight-bold">Additional Guest Details | </span>{row.original.customerName ? row.original.customerName : "Partner Guest"}
          </Grid>
          }
        </Grid>
      </Grid>
      <Grid
        item
        xs={3}
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center"
        }}
      >
        {row.original.paymentType != "CARD" && row.original.isShowReceiptImage && receiptImages &&
          <ShowImage images={receiptImages} rowDetails={row.original} reservationTable={true} />
        }
      </Grid>
    </Grid>
  );
};
class ReservationHistoryTable extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  getTime = date_string => {
    return moment(date_string)
      .utcOffset(0)
      .format("hh:mm A");
  };
  getDate = date_string => {
    return moment(date_string)
      .utcOffset(0)
      .format("MM/DD/YY");
  };

  getUtcTime = date_string => {
    var timeZoneOffset = moment(date_string)._tzm;
    return moment(date_string)
      .utcOffset(timeZoneOffset)
      .format("hh:mmA")
      .toLowerCase();
  };
  getUtcDate = date_string => {
    var timeZoneOffset = moment(date_string)._tzm;
    return moment(date_string)
      .utcOffset(timeZoneOffset)
      .format("MM/DD/YY");
  };

  onFetchData(state) {
    this.props.handleRequest(state);
    this.setState({ state });
  }
  render() {
    let totalCount = this.props.data.metaData ? this.props.data.metaData.totalCount : 1;
    return (
      <div>
        <ReactTable
          manual
          resizable={false}
          sortable={false}
          pages={Math.ceil(totalCount / 10)}
          onFetchData={state => this.onFetchData(state)}
          data={this.props.data.objects}
          loading={this.props.loading}
          columns={[
            {
              columns: [
                {
                  Header: "Transaction Date",
                  accessor: "startTimeAsIfUtc",
                  className: "primary-normal-font font-size-15 primary-text-color",
                  sortable: true,
                  
                  Cell: row => 
                    this.getDate(row.value)
                },
                {
                  Header: "Rewardable Spend",
                  accessor: "totalPreTipAndPreTaxSpendAmount",
                  className: "primary-normal-font font-size-15 primary-text-color",

                  Cell: row => {
                    if (row.value) {
                      return "$" + row.value;
                    } else {
                      return <div style={{fontWeight: 600}}>-</div>
                    }
                  }
                },
                {
                  Header: "Invoice Date",
                  accessor: "invoicedAt",
                  className: "primary-normal-font font-size-15 primary-text-color",
                  sortable: true,

                  Cell: row => {
                    console.log("invoice date")
                    console.log(row.original.isInvoiceExcluded)
                    console.log(row.value)
                    if (row.original.isInvoiceExcluded === true) {
                      return "Excluded from Invoice";
                    }
                    if (row.value) {
                      return this.getDate(row.value, "MM/DD/YY");
                    } else {
                      return <div style={{fontWeight: 600}}>-</div>
                    }
                  }
                },
                // {
                //   Header: "Party Size",
                //   accessor: "partySize",
                //   className: "primary-normal-font font-size-15 primary-text-color"
                // },
                {
                  width: 80,
                  Cell: row => {
                    if (row.original.paymentType == "CARD") {
                      return <span className="display-flex justify-content-center"><img src={card_icon} alt="CC Icon"/></span>
                    }
                    if (row.original.paymentType == "RECEIPT") {
                      return <span className="display-flex justify-content-center" style={{ marginTop: "-4px"}}><img src={receipt_icon} alt="Receipt Icon"/></span>
                    }
                  }
                }
              ]
            },
            {
              columns: [
                {
                  expander: true,
                  width: 65,
                  Expander: ({ isExpanded, ...rest }) => (
                    <div>
                      {isExpanded ? (
                        <span>&#x229D;</span>
                      ) : (
                        <span>&#x2295;</span>
                      )}
                    </div>
                  ),
                  style: {
                    cursor: "pointer",
                    fontSize: 25,
                    padding: "0",
                    textAlign: "center",
                    userSelect: "none"
                  }
                }
              ]
            }
          ]}
          PaginationComponent={props => <Pagination {...props} />}
          page={
            this.state.page !== this.props.page
              ? this.props.page
              : this.state.page
          }
          defaultPageSize={10}
          defaultSorted={[
            {
              id: "startTimeAsIfUtc",
              desc: false
            }
          ]}
          className="-striped "
          SubComponent={row => <ExpandedComponent row={row} />}
        />
        <br />
      </div>
    );
  }
}

export default ReservationHistoryTable;
