import React from "react";
import ReactTable from "react-table";
import "./table.css";
import Pagination from "./pagination";

import moment from "moment";

class ReservationsTable extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  getTime = date_string => {
    var timeZoneOffset = moment(date_string)._tzm;
    return moment(date_string)
      .utcOffset(timeZoneOffset)
      .format("hh:mmA");
  };

  getDate = date_string => {
    var timeZoneOffset = moment(date_string)._tzm;
    return moment(date_string)
      .utcOffset(timeZoneOffset)
      .format("MM/DD/YY");
  };

  onFetchData(state) {
    this.props.handleRequest(state);
  }
  render() {
    return (
      <div>
        <ReactTable
          manual
          resizable={false}
          pages={Math.ceil(this.props.data.reservations_count / 10)}
          data={this.props.data.reservations}
          loading={this.props.loading}
          onFetchData={state => this.onFetchData(state)}
          columns={[
            {
              columns: [
                {
                  Header: "Party Name",
                  accessor: "party_name",
                  className: "primary-normal-font font-size-15 primary-text-color"
                },
                {
                  Header: "Phone Number",
                  accessor: "phone_number",
                  className: "primary-normal-font font-size-15 primary-text-color"
                },
                {
                  Header: "Party Size",
                  accessor: "party_size",
                  className: "primary-normal-font font-size-15 primary-text-color"
                },
                {
                  Header: () => "Date",
                  accessor: "start_time",
                  className: "primary-normal-font font-size-15 primary-text-color",
                  Cell: row => this.getDate(row.value, "MM/DD/YY")
                },
                {
                  Header: () => "Time",
                  accessor: "start_time",
                  className: "primary-normal-font font-size-15 primary-text-color",
                  Cell: row => this.getTime(row.value)
                }
              ]
            }
          ]}
          PaginationComponent={props => <Pagination {...props} />}
          page={
            this.state.page !== this.props.page
              ? this.props.page
              : this.state.page
          }
          defaultPageSize={10}
          defaultSorted={[
            {
              id: "start_time",
              desc: false
            }
          ]}
          className="-striped "
        />
        <br />
      </div>
    );
  }
}

export default ReservationsTable;
