import React from 'react'



const BorderHorizontal = () => { 
    return ( 
        
        <div className="primary-line-color width-100 align-self-flex-end padding-top-30px border-bottom-width-1px border-bottom-style-solid"/>
        

        
    )

}

export default BorderHorizontal