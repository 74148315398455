import React from "react";

const TitleBoxView = ({ pageTitle, titleNumber, hasDollars }) => {
  return (
    <div>
      <div className="primary-text-color primary-normal-font font-size-24 padding-top-15px text-align-center">
        {pageTitle}
      </div>
      <div className="primary-text-color secondary-normal-font font-size-56">
        {hasDollars===true?'$':''} {titleNumber ? titleNumber.toLocaleString('en') : titleNumber}
      </div>
    </div>
  );
};

export default TitleBoxView;