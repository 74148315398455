import React from "react";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle/index";
import { reviewsRequest } from "../../actions/businessDataActions";
import RatingCell from '../../components/RatingCell/RatingCell'
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from 'moment';
class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page_number: 0,
      page_limit: 15,
      business_id:parseInt(this.props.match.params.id)
    };
  }
  componentDidMount() {
    let data = {
      page_limit: this.state.page_limit,
      page_number: this.state.page_number,
      business_id:this.state.business_id
    };
    
    this.props.reviewsRequest(data)
    window.gtag("event", "Reviews_page_viewed")
  }
  handleDate(date) {
    const timeZoneOffset = moment(date)._tzm;
    return moment(date).utcOffset(timeZoneOffset).format('MMM DD, YYYY');
  }

  expandReviews = () => {
    this.setState({
      page_number:this.state.page_number,
      page_limit:this.state.page_limit+15,
      business_id: this.state.business_id
    },()=>{
      this.props.reviewsRequest(this.state)
    })
  }
  render() {
    return (
      <div>
       
        { this.props.isFetchingReviews ? <CircularProgress className="position-fixed bottom-50 right-50"/> : 
         <div className="margin-top-20px"> 
           <PageTitle pageTitle={"Reviews"} />
          <div className="primary-text-color secondary-normal-font font-size-56 margin-top-bottom-48px margin-left-right-0px">{this.props.businessReviews.business_reviews.length > 0 ?
            `${parseFloat(this.props.businessReviews.business_reviews_average).toFixed(1)}/5` 
            : <span className="primary-medium-font font-size-24">No reviews are available yet</span>}</div>
            { this.props.businessReviews.business_reviews.map(r=>{
               return <RatingCell key={r.id} rating={r.rating}  name={r.user_name} date={this.handleDate(r.created_at)} image={r.user_image ? r.user_image.style.medium : null}/>
            })}
          <div className="primary-text-color text-align-right cursor-pointer" onClick={this.expandReviews} >See more</div>
        </div>
        }
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetchingReviews:state.businessData.isFetchingReviews,
    businessReviews: state.businessData.businessReviews,
    business_id: state.businessData.selectedBusiness,
    isAuthorized: state.auth.isAuthorized
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reviewsRequest: data => {
      dispatch(reviewsRequest(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reviews);
