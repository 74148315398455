import  * as actionTypes from "./actionTypes";

export const dashboardRequest = data => ({
  type: actionTypes.DASHBOARD_DATA_REQUEST,
  payload: data
});
export const dashboardRequestSuccess = data => ({
  type: actionTypes.DASHBOARD_DATA_REQUEST_SUCCESS,
  payload: data
});
export const dashboardRequestFailed = () => ({
  type: actionTypes.DASHBOARD_DATA_REQUEST_FAILED
});

export const getBusinessDataRequest = () => ({
    type: actionTypes.GET_BUSINESS_DATA_REQUEST,
  });
  export const getBusinessDataSuccess = (data) => ({
    type: actionTypes.GET_BUSINESS_DATA_REQUEST_SUCCESS,
    payload: data
  });
  export const getBusinessDataFailed = () => ({
    type: actionTypes.GET_BUSINESS_DATA_REQUEST_FAILED
  });
  
  export const getReservationsRequest = data => ({
    type: actionTypes.GET_RESERVATIONS_REQUEST,
    payload: data
  });
  export const getReservationsRequestSuccess = data => ({
    type: actionTypes.GET_RESERVATIONS_REQUEST_SUCCESS,
    payload: data
  });
  export const getReservationsRequestFailed = () => ({
    type: actionTypes.GET_RESERVATIONS_REQUEST_FAILED
  });

  export const getBillPaymentsRequest = data => ({
      type: actionTypes.GET_BILL_PAYMENTS_REQUEST,
      payload: data
    });
  
  export const getBillPaymentsRequestSuccess = data => ({
    type: actionTypes.GET_BILL_PAYMENTS_SUCCESS,
    payload:data
  });
  export const getBillPaymentsRequestFailed = () => ({
    type: actionTypes.GET_BILL_PAYMENTS_FAILED
  });

  export const getUnpaidBalanceRequest = data => ({
    type: actionTypes.GET_UNPAID_BALANCE_REQUEST,
    payload: data
  });

  export const getUnpaidBalanceRequestSuccess = data => ({
    type: actionTypes.GET_UNPAID_BALANCE_SUCCESS,
    payload: data
  });

  export const getUnpaidBalanceRequestFailed = () => ({
    type: actionTypes.GET_UNPAID_BALANCE_FAILED
  });

  export const reviewsRequest = data => ({
    type: actionTypes.BUSINESS_REVIEWS_REQUEST,
    payload: data
  });
  export const reviewsRequestSuccess = data => ({
    type: actionTypes.BUSINESS_REVIEWS_REQUEST_SUCCESS,
    payload: data
  });
  export const reviewsRequestFailed = () => ({
    type: actionTypes.BUSINESS_REVIEWS_REQUEST_FAILED
  });

  export const getStatementsRequest = data => ({
    type: actionTypes.GET_STATEMENTS_REQUEST,
    payload: data
  });
  
  export const getStatementsRequestSuccess = data => ({
    type: actionTypes.GET_STATEMENTS_REQUEST_SUCCESS,
    payload: data
  });
  export const getStatementsRequestFailed = () => ({
    type: actionTypes.GET_STATEMENTS_REQUEST_FAILED
  });

  export const statementsXlsDataRequest = data => ({
    type: actionTypes.STATEMENTS_XLS_REQUEST,
    payload: data
  });


export const updateBusinessAccountsV2Success = data => ({
  type: actionTypes.UPDATE_BUSINESS_ACCOUNTS_SUCCESS_V2,
  payload: data
});

export const updateBusinessAccountsV2Failed = data => ({
  type: actionTypes.UPDATE_BUSINESS_ACCOUNTS_FAILED_V2,
  payload: data
});

export const updateBusinessAccountsV2 = () => ({
  type: actionTypes.UPDATE_BUSINESS_ACCOUNTS_V2
});

export const getBusinessAccountsV2 = () => ({
  type: actionTypes.GET_BUSINESS_ACCOUNTS_V2
});

export const getBusinessAccountsSuccessV2 = data => ({
  type: actionTypes.GET_BUSINESS_ACCOUNTS_SUCCESS_V2,
  payload: data
});

export const getBusinessAccountsFailedV2 = data => ({
  type: actionTypes.GET_BUSINESS_ACCOUNTS_FAILED_V2,
  payload: data
});

export const businessDetailsRequest = (id) => ({
   type: actionTypes.BUSINESS_DETAILS_REQUEST,
   payload:id
});

export const businessDetailsRequestSuccess = data => ({
  type: actionTypes.BUSINESS_DETAILS_REQUEST_SUCCESS,
  payload:data
})

export const businessDetailsRequestFailed = () => ({
  type: actionTypes.BUSINESS_SCHEDULE_REQUEST_SUCCESS
})


export const businessScheduleRequest = (id) => ({
  type: actionTypes.BUSINESS_SCHEDULE_REQUEST,
  payload:id
});

export const businessScheduleRequestSuccess = data => ({
 type: actionTypes.BUSINESS_SCHEDULE_REQUEST_SUCCESS,
 payload:data
})

export const businessScheduleRequestFailed = () => ({
 type: actionTypes.BUSINESS_SCHEDULE_REQUEST_FAILED
})

export const setSelectedBusiness = (id) => ({
  type: actionTypes.SET_SELECTED_BUSINESS,
  payload:id
})

export const setSelectedChain= (id) => ({
  type: actionTypes.SET_SELECTED_CHAIN,
  payload:id
})

export const clearMessage = () =>({
  type: actionTypes.CLEAR_ERROR_MESSAGE,
})
export const getBusinessEmailsRequest = (id) => ({
  type: actionTypes.GET_BUSINESS_EMAILS,
  payload:id
})

export const getBusinessEmailsRequestSuccess = data => ({
  type: actionTypes.GET_BUSINESS_EMAILS_SUCCESS,
  payload:data
})

export const getBusinessEmailsRequestFailed = () => ({
  type: actionTypes.GET_BUSINESS_EMAILS_FAILED
})

export const getBusinessAccountsRequest = (id) => ({
  type: actionTypes.GET_BUSINESS_ACCOUNTS,
  payload:id
})

export const getBusinessAccountsRequestSuccess = data => ({
  type: actionTypes.GET_BUSINESS_ACCOUNTS_SUCCESS,
  payload:data
})

export const getBusinessAccountsRequestFailed = () => ({
  type: actionTypes.GET_BUSINESS_ACCOUNTS_FAILED
})
export const postBusinessAccountsRequest = (data) => ({
  type: actionTypes.POST_BUSINESS_ACCOUNTS,
  payload:data,
  businessId:data.business_id
  
})

export const postBusinessAccountsRequestSuccess = (id) => ({
  type: actionTypes.POST_BUSINESS_ACCOUNTS_SUCCESS,
  payload:{id,message:{message:"The account has been created.", variant:"success"}}
})

export const postBusinessAccountsRequestFailed = () => ({
  type: actionTypes.POST_BUSINESS_ACCOUNTS_FAILED
})
export const deleteBusinessAccountsRequest = (data) => ({
  type: actionTypes.DELETE_BUSINESS_ACCOUNTS,
  payload:data.id,
  businessId:data.businessId
  
})

export const deleteBusinessAccountsRequestSuccess = (id) => ({
  type: actionTypes.DELETE_BUSINESS_ACCOUNTS_SUCCESS,
  payload:{id,message:{message:"The account has been removed.", variant:"success"}}
})

export const deleteBusinessAccountsRequestFailed = () => ({
  type: actionTypes.DELETE_BUSINESS_ACCOUNTS_FAILED
})
export const updateBusinessAccountsRequest = (action) => ({
  type: actionTypes.UPDATE_BUSINESS_ACCOUNTS,
  payload:action.data,
  id:action.id,
  businessId:action.businessId
  
})

export const updateBusinessAccountsRequestSuccess = (id) => ({
  type: actionTypes.UPDATE_BUSINESS_ACCOUNTS_SUCCESS,
  payload:{id,message:{message:"The account has been updated.", variant:"success"}}
})

export const updateBusinessAccountsRequestFailed = () => ({
  type: actionTypes.UPDATE_BUSINESS_ACCOUNTS_FAILED
})
export const postBusinessEmailsRequest = (data) => ({
  type: actionTypes.POST_BUSINESS_EMAILS,
  payload:data.userData,
  businessId:data.business_id
  
})

export const postBusinessEmailsRequestSuccess = (id) => ({
  type: actionTypes.POST_BUSINESS_EMAILS_SUCCESS,
  payload:{id,message:{message:"The account has been created.", variant:"success"}}
})

export const postBusinessEmailsRequestFailed = () => ({
  type: actionTypes.POST_BUSINESS_EMAILS_FAILED
})
export const deleteBusinessEmailsRequest = (data) => ({
  type: actionTypes.DELETE_BUSINESS_EMAILS,
  payload:data.id,
  businessId:data.businessId
  
})

export const deleteBusinessEmailsRequestSuccess = (id) => ({
  type: actionTypes.DELETE_BUSINESS_EMAILS_SUCCESS,
  payload:{id,message:{message:"The account has been removed.", variant:"success"}}
})

export const deleteBusinessEmailsRequestFailed = () => ({
  type:actionTypes.DELETE_BUSINESS_EMAILS_FAILED
})
export const updateBusinessEmailsRequest = (action) => ({
  type: actionTypes.UPDATE_BUSINESS_EMAILS,
  payload:action.data,
  id:action.id,
  businessId:action.businessId,
  
})

export const updateBusinessEmailsRequestSuccess = (id) => ({
  type: actionTypes.UPDATE_BUSINESS_EMAILS_SUCCESS,
  payload:{id,message:{message:"The account has been updated.", variant:"success"}}
})

export const updateBusinessEmailsRequestFailed = () => ({
  type:actionTypes.UPDATE_BUSINESS_EMAILS_FAILED
})

export const changeBusinessAccountUser = (data) =>({
  type: actionTypes.CHANGE_BUSINESS_ACCOUNT_USER,
  payload:data
})


export const createBusinessInfoRequest = (data) => ({
  type: actionTypes.CREATE_BUSINESS_INFO_REQUEST,
  payload:data,
}) 

export const createBusinessInfoRequestSuccess = () => ({
  type:actionTypes.CREATE_BUSINESS_INFO_REQUEST_SUCCESS,
  payload:{message: "Your request was received, we will contact you for any further changes", variant:"success"}
})

export const createBusinessInfoRequestFailed = () => ({
  type: actionTypes.CREATE_BUSINESS_INFO_REQUEST_FAILED
})
export const resetPasswordRequest = (action) =>({
  type: actionTypes.RESET_PASSWORD,
  payload:{email:action}
})

export const resetPasswordRequestSuccess = (message) => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
  payload: message
})

export const resetPasswordRequestFailed = (message) => ({
  type: actionTypes.RESET_PASSWORD_FAILED,
  payload:message
})

export const updatePasswordRequest = (data) =>({
  type: actionTypes.UPDATE_PASSWORD_REQUEST,
  payload:data
})

export const updatePasswordRequestSuccess = (message) => ({
  type: actionTypes.UPDATE_PASSWORD_REQUEST_SUCCESS,
  payload: message
})

export const updatePasswordRequestFailed = (message) => ({
  type: actionTypes.UPDATE_PASSWORD_REQUEST_FAILED,
  payload:message
})

export const uploadBusinessImageRequest = (data) => ({
  type: actionTypes.UPLOAD_BUSINESS_IMAGE,
  payload:data
}) 
export const uploadBusinessImageRequestSuccess = (data) => ({
  type: actionTypes.UPLOAD_BUSINESS_IMAGE_SUCCESS,
  payload:data
  
 
}) 
export const uploadBusinessImageRequestFailed = () => ({
  type: actionTypes.UPLOAD_BUSINESS_IMAGE_FAILED,
  
}) 


export const unsubscribe = (token)=>({
  type: actionTypes.UNSUBSCRIBE_FROM_EMAILS,
  payload:token
})

export const unsubscribeSuccess = (message) => ({
  type:actionTypes.UNSUBSCRIBE_EMAILS_SUCCESS,
  payload:{message: message, variant:"success"}
})

export const confirmReservation = token => ({
  type:actionTypes.CONFIRM_RESERVATION_REQUEST,
  payload:token
})

export const confirmReservationSuccess = (message) => ({
  type:actionTypes.CONFIRM_RESERVATION_SUCCESS,
  payload:{message: message, variant:"success"}
})

export const rejectReservation = token => ({
  type:actionTypes.REJECT_RESERVATION_REQUEST,
  payload:token
})

export const rejectReservationSuccess = (message) => ({
  type:actionTypes.REJECT_RESERVATION_SUCCESS,
  payload:{message: message, variant:"success"}
})