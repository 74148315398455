import React from "react";
import { connect } from "react-redux";
import { dashboardRequest } from "../../actions/businessDataActions";
import PageTitle from "../../components/PageTitle/index";
import TitleBoxView from "../../components/TitleBoxView/index";
import DropDown from "../../components/Dropdown/index";
import moment from "moment";
import "./home.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { resetIsAuthorized } from "../../actions/auth";
import DineInDashboard from "../../components/DashBoardView/dineIn";
import SahDashboard from "../../components/DashBoardView/sah";

class Home extends React.Component {

  getAllTimes(){
    return {
      end_time: this.$moment()
        .date(this.$moment().date() - 1)
        .hours(23)
        .minutes(59)
        .format("YYYY-MM-DD HH:mm"),
      start_time: this.$moment()
        .date(this.$moment().date() - 1000)
        .hours(0)
        .minutes(0)
        .format("YYYY-MM-DD HH:mm"),
    }
  };

  getLastXDays(item) {
    var new_item = null;
    var months = null;
    var start_month=null;
    var end_month=null;
    for (var i = 1; i < 12; i++) {
      if (item === 30 * i) {
        start_month = this.$moment().month() - i;
        end_month = this.$moment().month();
        if (end_month - start_month > 0) {
          months = Number(end_month - start_month);
        } else {
          months = Number(12 + end_month - start_month);
        }

        for (var j = 1; j <= months; j++) {
          if (this.$moment().month() - j === (3 || 5 || 8 || 10)) {
            new_item += 30;
          } else if (this.$moment().month() - j === 1) {
            new_item += 28;
          } else {
            new_item += 31;
          }
        }
      }
    }
    if (!new_item) {
      new_item = item;
    }
    
    return {
      end_time: this.$moment()
        .date(this.$moment().date() - 1)
        .hours(23)
        .minutes(59)
        .format("YYYY-MM-DD HH:mm"),
      start_time: this.$moment()
        .date(this.$moment().date() - 1 - new_item)
        .hours(0)
        .minutes(0)
        .format("YYYY-MM-DD HH:mm")
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      data :{
        ...this.getLastXDays(30),  
        business_id: parseInt(this.props.match.params.id),
        product: "DINE_IN",
      }
    };
  }
  $moment = moment;

  componentDidMount() {
    this.getDashboard();
    window.gtag("event", "Home_page_viewed")
  }
  getDashboard() {
    let data = this.state.data;
    let domain_type = this.props.match.params.entity;
    if (domain_type !== "chain") {
      this.props.dashboardRequest(data);
    } else {
      delete data.business_id;
      this.props.dashboardRequest(data);
    }
    this.setState(this.getLastXDays(30));
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if ((parseInt(this.props.match.params.id) === this.props.selectedBusiness) & (prevProps.selectedBusiness === null)) {
      let data = { ...this.state, business_id:this.props.match.params.id, product: this.state.data.product };
      this.props.dashboardRequest(data);
    }
    if(this.props.selectedBusiness === null & prevProps.selectedBusiness !== null){
      let data = { ...this.state, product: this.state.data.product };
      delete data.business_id;
      this.props.dashboardRequest(data);
    }
  }

  onSelect = (string_item, options) => {
    console.log(string_item, options)
    if (string_item == "custom_range"){
      var state = Object.assign(this.state.data, {
        start_time: this.$moment(options.start_time).format("YYYY-MM-DD HH:mm"),
        end_time: this.$moment(options.end_time).hours(23).minutes(59).format("YYYY-MM-DD HH:mm"),
        business_id: parseInt(this.props.match.params.id),
        product: this.state.data.product
      });
      window.gtag("event", "business_stats_time_range_filtered", {start_time: state.start_time, end_time: state.end_time})
      this.setState({ data:state }, () => this.getDashboard());

      return;
    }

    
    if (string_item !== "All time") {
      if (string_item !== "") {        
        var item = string_item.match(/\d/g);
        if (item) {
          item = Number(item.join(""));
          var { end_time, start_time } = this.getLastXDays(item);
      } else {
        
        start_time = this.$moment(options.start_time).format(
          "YYYY-MM-DD HH:mm"
        );
        end_time = this.$moment(options.end_time)
          .hours(23)
          .minutes(59)
          .format("YYYY-MM-DD HH:mm");
      }
    } else {
      ({ end_time, start_time } = this.getLastXDays(30));
    }
    var state = Object.assign(this.state.data, {
      start_time: start_time,
      end_time: end_time,
      business_id: parseInt(this.props.match.params.id),
      product: this.state.data.product
    });
    window.gtag("event", "business_stats_time_range_filtered", {start_time: state.start_time, end_time: state.end_time})
    this.setState({ data:state }, () => this.getDashboard());
  } else {
    window.gtag("event", "business_stats_time_range_filtered", this.getAllTimes())
    this.setState({data:{...this.getAllTimes(), business_id: parseInt(this.props.match.params.id), product: this.state.data.product}},
    ()=>this.getDashboard());
  };
  }

  changeFilterBy = params => {
    var state = Object.assign(this.state.data, {
      business_id: parseInt(this.props.match.params.id),
      product: params
    });
    window.gtag("event", "business_stats_modality_filtered", {modality_selected: params})
    this.setState({ data:state }, () => this.getDashboard());
  };

  render() {
    let domainType = this.props.match.params.entity;
    let dashboardData = this.props.dashboardData;
    let defaultProduct = this.props.dashboardData.defaultProduct;
    let detailView;
    const product = this.state.data.product;
    if (product == "DINE_IN" || (product == "DEFAULT" && defaultProduct == "DINE_IN")) {
      detailView = <DineInDashboard dashboardData={this.props.dashboardData}/>
    } else if (product == "SAH" || (product == "DEFAULT" && defaultProduct == "SAH")) {
      detailView =  <SahDashboard dashboardData={this.props.dashboardData}/>
    } else {
      detailView =  null;
    }
    const items = ["All time", "Last 90 days", "Last 30 days", "Last 7 days"];
    return (
      <div>
        <div style={{ overflow: "hidden" }}>
          <Hidden smUp>
            <div style={{ marginBottom: 50, alignContent: "center" }}>
              <PageTitle pageTitle={"Business Statistics"} />
            </div>
          </Hidden>
          <div className="home-page-container">
            <Hidden smDown>
              <PageTitle pageTitle={"Business Statistics"} />
            </Hidden>
            <DropDown
              future={false}
              handleSelect={this.onSelect}
              items={items}
              selectedOptionNumber={2}
            />
          </div>
          {this.props.isFetching ? (
            <CircularProgress
              style={{ position: "fixed", bottom: "50%", right: "40%" }}
            />
          ) : (
            <Grid
              container
              direction="column"
              justify="space-evenly"
              style={{ marginBottom: 50 }}
            >
              <Grid container direction="row" justify="space-evenly">
                {domainType == 'chain' && Object.keys(dashboardData).length == 0 ? 
                  <span className="primary-medium-font font-size-24 margin-top-45px">No businesses are linked to this chain!</span>
                  : 
                  <TitleBoxView
                    pageTitle="Revenue Driven"
                    titleNumber={dashboardData.revenueDriven}
                    hasDollars={true}
                  />
                }
                {detailView}
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dashboardData: state.businessData.dashboardData,
    isFetching: state.businessData.isFetchingDashboardData,
    selectedBusiness:state.businessData.selectedBusiness,
    isAuthorized: state.auth.isAuthorized
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dashboardRequest: data => {
      dispatch(dashboardRequest(data));
    },
    resetIsAuthorized: () => {
      dispatch(resetIsAuthorized());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
