import { AccountCircle } from "@material-ui/icons";
import Home from "../containers/Home/Home";
import Profile from "../containers/Profile/Profile";
import Reservation from "../containers/Reservations/Reservations";
import Reviews from '../containers/Reviews/Reviews';
import Team from '../containers/Team/Team';
import History from '../containers/History/History'
import Payments from '../containers/Payments/Payments'
// ICONS
const homeIcon = require("../../src/assets/icons/home_icon.png");
const profileIcon = require("../../src/assets/icons/profile_icon.png");
const reservationIcon = require("../../src/assets/icons/reservations_icon.png")
const userIcon = require("../../src/assets/icons/user_icon.png")
const reviewsIcon = require("../../src/assets/icons/reviews_icon.png")
const history = require("../../src/assets/icons/history_icon.png")
const payments = require("../../src/assets/icons/statements_icon.png")
const Routes = [
  {
    path: "home",
    sidebarName: "Home",
    navbarName: "Home",
    icon: homeIcon,
    component: Home,
    allLocations:true
  },
  // {
  //   path: "reservations",
  //   sidebarName: "Reservations",
  //   navbarName: "Reservations",
  //   icon: reservationIcon,
  //   component: Reservation,
  //   allLocations:true
  // },
  {
    path: "team",
    sidebarName: "Team",
    navbarName: "Team",
    icon: userIcon,
    component: Team,
    allLocations:true
  },
  {
    path: "profile",
    sidebarName: "Profile",
    navbarName: "Profile",
    icon: profileIcon,
    component: Profile,
    allLocations:false
  },
  // {
  //   path: "reviews",
  //   sidebarName: "Reviews",
  //   navbarName: "Reviews",
  //   icon: reviewsIcon,
  //   component: Reviews,
  //   allLocations:false
  // },
  {
    path: "history",
    sidebarName: "History",
    navbarName: "History",
    icon: history,
    component: History,
    allLocations:true
  },
  {
    path: "payments",
    sidebarName: "Payments",
    navbarName: "Payments",
    icon: payments,
    component: Payments,
    allLocations:true
  }
];

export default Routes;
