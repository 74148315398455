import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import { bool } from "prop-types";
import DialogTitle from "../../../components/Dialog/DialogTitle/index";
import { withStyles } from "@material-ui/core/styles";
import EditUserForm from "./EditUserForm";
import { compose } from 'recompose'
import { connect } from 'react-redux'

const styles = {
  rootStyle: {
    borderRadius: 0
  }
};
const editIcon = require("../../../assets/icons/edit_icon.png");

class EditUserPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      emailType: {
        Invoices: bool,
        Confirmations: bool,
        Cancellations: bool,
        Admin: bool
      },
      password: "",
      id: "",
      wasAdmin: false,
      isFormValid: true,
      isPasswordRequired:false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    if (this.props.data.isAdmin === true) {
      this.setState({
        wasAdmin: true
      });
    }
  }

  componentDidUpdate(prevProps){
    console.log('MESSAGES', this.props.validateMessage)
    if(this.props.validateMessage.variant !== prevProps.validateMessage.variant){
  
      if(this.props.validateMessage.variant === "error" & !this.state.isPasswordRequired) {
        this.setState({isFormValid:false}) 
      }else {
        this.setState({isFormValid: true})
        this.handleClose()
      }
    }
}

  handleClick = event => {
    this.handleData();
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleData() {
    if (this.props.data.email_type === "confirmation") {
      let emailtype = Object.assign({}, this.state.emailType);
      emailtype.Confirmations = true;
      this.setState({
        emailType: emailtype
      });
    }
    if (this.props.data.email_type === "cancellation") {
      let emailtype = Object.assign({}, this.state.emailType);
      emailtype.Cancellations = true;
      this.setState({
        emailType: emailtype
      });
    }
    if (this.props.data.email_type === "invoice") {
      let emailtype = Object.assign({}, this.state.emailType);
      emailtype.Invoices = true;
      this.setState({
        emailType: emailtype
      });
    }
    if (this.props.data.isAdmin) {
      let emailtype = Object.assign({}, this.state.emailType);
      emailtype.Admin = true;
      this.setState({
        emailType: emailtype
      });
    }
   
    this.setState({
      name: this.props.data.name,
      email: this.props.data.email,
      password: this.props.data.password,
      id: this.props.data.id
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

 
    if (name === "Confirmations") {
      let emailtype = Object.assign({}, this.state.emailType);
      emailtype.Confirmations = value;
      this.setState({
        emailType: emailtype
      });
    } else if (name === "Cancellations") {
      let emailtype = Object.assign({}, this.state.emailType);
      emailtype.Cancellations = value;
      this.setState({
        emailType: emailtype
      });
    } else if (name === "Invoices") {
      let emailtype = Object.assign({}, this.state.emailType);
      emailtype.Invoices = value;
      this.setState({
        emailType: emailtype
      });
    } else if (name === "Admin") {
      let emailtype = Object.assign({}, this.state.emailType);
      emailtype.Admin = value;
      this.setState({
        emailType: emailtype
      });
    } 
    if(name==="password"){
      this.setState({isFormValid:false,
        [name]:value
    })
    value.length >= 8 ? this.setState({ isFormValid:true , isPasswordRequired:false}) : this.setState({isPasswordRequired:true})
    }     
    else{
    this.setState({
      [name]:value
    })};
  
  }
  onSubmit = () => {
    !this.state.isPasswordRequired && this.props.handleUpdate(this.state);
  };
  onDelete = () => {
    this.props.handleDelete(this.state);
    this.handleClose();
  };

  render() {
    
    const { classes } = this.props;
   
    return (
      <div className="text-align-right">
        <Button onClick={this.handleClick}>
          <img className="width-20px primary-medium-font"
            alt=""
            src={editIcon}
          />
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          classes={{ paper: classes.rootStyle }}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            USER INFORMATION
          </DialogTitle>
          <EditUserForm
            isFormValid={this.state.isFormValid}
            name={this.state.name}
            email={this.state.email}
            handleInputChange={this.handleInputChange}
            invoices={this.state.emailType.Invoices}
            confirmations={this.state.emailType.Confirmations}
            cancellations={this.state.emailType.Cancellations}
            admin={this.state.emailType.Admin}
            password={this.state.password}
            open={this.state.open}
            onSubmit={this.onSubmit}
            onDelete={this.onDelete}
            isFormValid={this.state.isFormValid}
            isPasswordRequired={this.state.isPasswordRequired}
          />
        </Dialog>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  validateMessage: state.businessData.message
})

const mapDispatchToProps = (dispatch) => ({

})

export default compose(connect(mapStateToProps,mapDispatchToProps), withStyles(styles))(EditUserPopUp);


