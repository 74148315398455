import React from "react";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import "./imageGrid.css";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
const ImageGrid = ({ isFetching, images, mainImage }) => {
  console.log(images)
  return (
    <div className="root" style={{backgroundColor:'transparent'}}>
      {isFetching ? (
        <CircularProgress />
      ) : (
        <div>
       
        <GridList cellHeight={200} className="gridList" cols={5}>
        <GridListTile cols={1}/>
        <GridListTile  cols={3}>
        <img src={mainImage != null ? mainImage.style.large : null} />
        </GridListTile>
        <GridListTile cols={1}/>
        
          {images.map((img, i )=> (
            <GridListTile  key={img.id} cols={i === 0 ? 0: 1}>
              <img src={img.style.large} />
            </GridListTile>
          ))}
          {/* <GridListTile cols={1}>
            <input
              accept="image/*"
              className="input"
              id="contained-button-file"
              multiple
              type="file"
              
            />
            <label
             style={{display:"flex",flex:1,height:200}}
              htmlFor="contained-button-file">
              <Button
                style={{display:"flex",flex:1, fontFamily:'Lato-medium'}}
                variant="contained"
                component="span"
                disableRipple={true}
                disableTouchRipple={true}
                disableFocusRipple={true}
              >
                <AddIcon />
              </Button>
            </label>
          </GridListTile> */}
        </GridList>
        </div>
      )}
    </div>
  );
};

export default ImageGrid;
