import React from "react";
import { connect } from "react-redux";
import { updatePasswordRequest } from "../../actions/businessDataActions";
import {
  withRouter
} from 'react-router-dom'
import { Typography, Button } from "@material-ui/core";
import "../Login/login.css";
import queryString from "query-string";
import { compose } from "recompose";
const logo = require("../../assets/images/white.png");

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      email: "",
      token: ""
    };
  }
  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    console.log(values.email, values.token);

    this.setState({
      email: values.email,
      token: values.token,
      password: this.state.password
    });
  }

 componentDidUpdate(prevProps){
   if(this.props.passwordUpdated !== prevProps.passwordUpdated){
    this.props.history.push('/login')
   } else {
     return
   }

 }
  handleChangeInput = e => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.value);
  };

  handleResetPassword = () => {
    let data= this.state;
    console.log("go to login page", this.state.password);
    console.log("password has benn reset");
    this.props.updatePasswordRequest(data);
  };

  render() {
    const styles = {
      h6: {
        color: "white",
        marginBottom: 30
      },
      subtitle1: {
        color: "white",
        marginBottom: 20
      }
    };
    return (
      <div id="authViewContainer">
        <div className="auth-view-background-container">
          <div className="auth-view-background">
            <div className="auth-view-background-black-layer" />
          </div>
        </div>
        <div className="auth-view-container vertical-auth-flex-container">
          <div className="auth-flex-item auth-logo-container">
            <div
              className="auth-logo"
              style={{ backgroundImage: `url(${logo})` }}
            />
            <div className="auth-logo-text is-g-medium">
              <span className="primary-medium-font">
                Restaurant Portal
              </span>
            </div>
          </div>
          {/* FORMS */}
          <div className="auth-flex-item">
            <div className="auth-forms-wrapper">
              <div className="auth-form login-form ">
                <Typography
                  style={styles.h6}
                  variant="h6"
                  align="left"
                  //   gutterBottom
                >
                  RESET PASSWORD
                </Typography>
                <Typography
                  style={styles.subtitle1}
                  variant="subtitle1"
                  align="left"
                  //   gutterBottom
                >
                  Please enter your new password
                </Typography>

                <div className="auth-flex-item login-form-input-container is-g-regular">
                  <input
                    style={styles.input}
                    type="password"
                    placeholder="new password"
                    value={this.state.password}
                    name="password"
                    autoComplete="off"
                    onChange={this.handleChangeInput}
                    autoComplete="off"
                  />
                </div>
                <div className="auth-flex-item login-form-button-container is-g-semiBold">
                  <Button
                    className="auth-button btn-hvr-an margin-top-20px"
                    onClick={this.handleResetPassword}
                  >
                    RESET PASSWORD
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    passwordUpdated:store.businessData.passwordUpdated
  }
}
const mapDispatchToProps = dispatch => {
  return {
    updatePasswordRequest: data => {
      dispatch(updatePasswordRequest(data));
    }
  };
};

export default compose(withRouter,connect(mapStateToProps,mapDispatchToProps))(ResetPassword);
