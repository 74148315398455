import React from 'react'



const BorderVertical = () => { 
    return ( 
        
        <div className="primary-line-color height-300px align-self-center border-right-width-1px border-right-style-solid"/>
        

        
    )

}

export default BorderVertical