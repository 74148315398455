import React from "react";
import PageTitle from "../../components/PageTitle/index";
import ReservationsTable from "../../components/DataTables/reservationsTable";
import Grid from "@material-ui/core/Grid";
import { getReservationsRequest } from "../../actions/businessDataActions";
import moment from "moment";
import { connect } from "react-redux";
import DropDown from "../../components/Dropdown/index";

class Reservations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        page_limit: 10,
        relative_date_filter: "upcoming",
        order: "asc",
        order_by: "start_time",
        party_size: null,
        phone_number: "",
        start_time: null,
        end_time: this.$moment()
          .date(this.$moment().date() + 31)
          .hours(23)
          .minutes(59)
          .format("YYYY-MM-DD HH:mm")
      },
      business_id: parseInt(this.props.match.params.id),
      domain_type: this.props.match.params.entity
    };
  }
  $moment = moment;

  componentDidMount() {
    window.gtag("event", "Reservations_page_viewed")
  }

  handleRequest = tableState => {
    let data = {
      page_number: tableState.page,
      page_limit: tableState.pageSize,
      relative_date_filter: "upcoming",
      order:
        tableState.sorted.length > 0 && tableState.sorted[0].desc === false
          ? "desc"
          : "asc",
      order_by:
        tableState.sorted.length > 0 ? tableState.sorted[0].id : "start_time",
      start_time: this.state.data.start_time,
      end_time: this.state.data.end_time,
      business_ids:
        this.state.domain_type === "chain" ? null : this.state.business_id
    };
    this.props.getReservations(data);
  };

  onSelect = (string_item, options) => {
    if (string_item === "custom_range") {
      var start_time = this.$moment(options.start_time).format(
        "YYYY-MM-DD HH:mm"
      );
      var end_time = this.$moment(options.end_time)
        .hours(23)
        .minutes(59)
        .format("YYYY-MM-DD HH:mm");
      var state = Object.assign(this.state.data, {
        start_time: start_time,
        end_time: end_time,
        page_number: 0,
        page_limit: 10,
        business_ids:
          this.state.domain_type === "chain" ? null : this.state.business_id
      });
      this.setState({ data: state }, () =>
        this.props.getReservations(this.state.data)
      );
      return;
    }
    if (string_item !== "All Upcoming") {
      if (string_item !== "") {
        var item = string_item.match(/\d/g);
        if (item) {
          item = Number(item.join(""));
          var new_item = null;
          var months = null;
          for (var i = 1; i < 12; i++) {
            if (item === 30 * i) {
              var end_month = this.$moment().month() + i;
              var start_month = this.$moment().month();
              if (end_month - start_month > 0) {
                months = Number(end_month - start_month);
              } else {
                months = Number(12 + end_month - start_month);
              }
              for (var j = 0; j < months; j++) {
                if (this.$moment().month() + j === (3 || 5 || 8 || 10)) {
                  new_item += 30;
                } else if (this.$moment().month() + j === 1) {
                  if (
                    Number(this.$moment().year(this.$moment().month() - j)) %
                      4 ===
                    0
                  ) {
                    new_item += 29;
                  }
                  new_item += 28;
                } else {
                  new_item += 31;
                }
              }
            }
          }
          if (!new_item) {
            new_item = item;
          }
          start_time = this.$moment()
            .date(this.$moment().date())
            .hours(0)
            .minutes(0)
            .format("YYYY-MM-DD HH:mm");
          end_time = this.$moment()
            .date(this.$moment().date() + new_item)
            .hours(23)
            .minutes(59)
            .format("YYYY-MM-DD HH:mm");
        } else if (string_item === "Tomorrow") {
          start_time = this.$moment()
            .date(this.$moment().date() + 1)
            .hours(0)
            .minutes(0)
            .format("YYYY-MM-DD HH:mm");
          end_time = this.$moment()
            .date(this.$moment().date() + 1)
            .hours(23)
            .minutes(59)
            .format("YYYY-MM-DD HH:mm");
        } else if (string_item === "Coming Week") {
          start_time = this.$moment()
            .date(this.$moment().date())
            .hours(0)
            .minutes(0)
            .format("YYYY-MM-DD HH:mm");
          end_time = this.$moment()
            .date(this.$moment().date() + 7)
            .hours(23)
            .minutes(59)
            .format("YYYY-MM-DD HH:mm");
        }
      }
    } else {
      start_time = null;
      end_time = this.$moment()
        .date(this.$moment().date() + 31)
        .hours(23)
        .minutes(59)
        .format("YYYY-MM-DD HH:mm");
    }
    state = Object.assign(this.state.data, {
      start_time: start_time,
      end_time: end_time,
      page_limit: 10,
      business_ids:
        this.state.domain_type === "chain" ? null : this.state.business_id
    });
    this.setState({ data: state }, () =>
      this.props.getReservations(this.state.data)
    );
  };
  render() {
    const items = ["All Upcoming", "Tomorrow", "Coming Week", "Coming 30 days"];
    return (
      <div>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <PageTitle pageTitle={"Reservations"} />
            <div style={{ marginTop: "32px" }}>
              <DropDown
                future={true}
                handleSelect={this.onSelect}
                items={items}
              />
            </div>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "24px" }}>
            <ReservationsTable
              page={this.state.data.page_number}
              handleRequest={this.handleRequest}
              data={this.props.reservations}
              loading={this.props.isFetchingReservations}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    business_id: state.businessData.business.domain.id,
    reservations: state.businessData.reservations,
    isFetchingReservations: state.businessData.isFetchingReservations,
    selectedBusiness: state.businessData.selectedBusiness
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReservations: data => {
      dispatch(getReservationsRequest(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reservations);
