import React from "react";
import PageTitle from "../../components/PageTitle/index";
import PaymentsTable from "../../components/DataTables/paymentsTable";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { connect } from "react-redux";
import { getBillPaymentsRequest, getUnpaidBalanceRequest } from "../../actions/businessDataActions";
import CircularProgress from "@material-ui/core/CircularProgress";

class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        page_number: 0,
        page_limit: 10,
        order_by_desc: true,
        order_by: "createdAt",
        start_time: null,
        end_time: null
      },
      business_id: parseInt(this.props.match.params.id),
      domain_type: this.props.match.params.entity
    };
  }
  $moment = moment;

  componentDidMount() {
    window.gtag("event", "Payments_page_viewed")
    var data = {business_ids:
        this.state.domain_type === "chain" ? null : this.state.business_id
    };
    this.props.getUnpaidBalance(data);
  }

  onSelect = (string_item, options) => {
    if (string_item !== "All") {
      if (string_item !== "") {
        var item = string_item.match(/\d/g);
        if (item) {
          item = Number(item.join(""));
          var new_item = null;
          var months = null;
          for (var i = 1; i < 12; i++) {
            if (item === 30 * i) {
              var start_month = this.$moment().month() - i;
              var end_month = this.$moment().month();
              if (end_month - start_month > 0) {
                months = Number(end_month - start_month);
              } else {
                months = Number(12 + end_month - start_month);
              }
              for (var j = 1; j <= months; j++) {
                if (this.$moment().month() - j === (3 || 5 || 8 || 10)) {
                  new_item += 30;
                } else if (this.$moment().month() - j === 1) {
                  new_item += 28;
                } else {
                  new_item += 31;
                }
              }
            }
          }
          if (!new_item) {
            new_item = item;
          }
          var end_time = this.$moment()
            .date(this.$moment().date())
            .hours(23)
            .minutes(59)
            .format("YYYY-MM-DD HH:mm");
          var start_time = this.$moment()
            .date(this.$moment().date() - new_item)
            .hours(0)
            .minutes(0)
            .format("YYYY-MM-DD HH:mm");
        } else if (string_item === "Yesterday") {
          start_time = this.$moment()
            .date(this.$moment().date() - 1)
            .hours(0)
            .minutes(0)
            .format("YYYY-MM-DD HH:mm");
          end_time = this.$moment()
            .date(this.$moment().date() - 1)
            .hours(23)
            .minutes(59)
            .format("YYYY-MM-DD HH:mm");
        }
      } else {
        start_time = this.$moment(options.start_time).format(
          "YYYY-MM-DD HH:mm"
        );
        end_time = this.$moment(options.end_time)
          .hours(23)
          .minutes(59)
          .format("YYYY-MM-DD HH:mm");
      }
    } else {
      end_time = this.$moment()
        .date(this.$moment().date() - 1)
        .hours(23)
        .minutes(59)
        .format("YYYY-MM-DD HH:mm");
      start_time = this.$moment()
        .date(this.$moment().date() - 1000)
        .hours(0)
        .minutes(0)
        .format("YYYY-MM-DD HH:mm");
    }
    var state = Object.assign(this.state.data, {
      start_time: start_time,
      end_time: end_time,
      page_number: 0,
      page_limit: 5,
      business_ids:
        this.state.domain_type === "chain" ? null : this.state.business_id
    });
    this.setState({ data: state }, () =>
      this.props.getBillPaymentsRequest(state)
    );
  };

  handleRequest = tableState => {
    console.log(tableState.sorted);
    let data = {
      page_number: tableState.page,
      page_limit: tableState.pageSize,
      order_by_desc:
        tableState.sorted.length > 0 && tableState.sorted[0].desc === false
          ? true : false,
      order_by: tableState.sorted.length > 0
          ? tableState.sorted[0].id === "createdAt"
            ? "createdAt" : tableState.sorted[0].id : "createdAt",
      start_time: this.state.data.start_time,
      end_time: this.state.data.end_time,
      business_ids:
        this.state.domain_type === "chain" ? null : this.state.business_id
    };
    this.props.getBillPaymentsRequest(data);
  };

  render() {
    // const items = ["All", "Yesterday", "Last 7 days", "Last 30 days"];
    return (
      <div>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <PageTitle pageTitle={"Recent Payments"} />
          </Grid>
          <Grid item xs={6}>
            {this.props.isFetchingUnpaidBalance? (
              <CircularProgress
              style={{ position: "fixed", top: "8%", right: "8%" }}
            />
            ):(
            <div className="primary-text-color secondary-normal-font font-size-24 text-align-right">
            Unpaid Balance: ${this.props.unpaidBalance.unpaidBalance}</div>
            )}
  
          </Grid>
          <Grid container spacing={8} style={{ marginTop: "24px" }}>
            {}
            {}
          </Grid>

          <Grid item xs={12} style={{ marginTop: "24px" }}>
            <PaymentsTable
              handleRequest={this.handleRequest}
              data={this.props.billPayments}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    billPayments: state.businessData.billPayments,
    unpaidBalance: state.businessData.unpaidBalance,
    isFetchingUnpaidBalance: state.businessData.isFetchingUnpaidBalance,
    isAuthorized: state.auth.isAuthorized,
    isFetchingBillPayments:
      state.businessData.isFetchingBillPayments,
    selectedBusiness: state.businessData.selectedBusiness
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBillPaymentsRequest: data => {
      dispatch(getBillPaymentsRequest(data));
    },
    getUnpaidBalance: data => {
      dispatch(getUnpaidBalanceRequest(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payments);
