import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isAuthorized: false,
  authData: {},
  message:{message:'', variant:''}
};
const authReducer = (state = initialState, action) => {
  // console.log("ACTION TYPE", action);
  switch (action.type) {
    case actionTypes.AUTH_REQUEST:
      return Object.assign({}, state, {
        isAuthorized: false
      });
    case actionTypes.AUTH_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isAuthorized: true,
        authData: action.payload
      });
    case actionTypes.AUTH_REQUEST_FAILED: 
      return Object.assign({},state,{
        isAuthorized:false,
        message: action.payload
      })
    case actionTypes.RESET_IS_AUTHORIZED: {
      return Object.assign({}, state, {
        isAuthorized: false,
      });
    }
    case actionTypes.GET_BUSINESS_DATA_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        isAuthorized: true,
      });
    }
    default:
      return state;
  }
};

export default authReducer;
