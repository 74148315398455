import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Dialog from '@material-ui/core/Dialog';
import { bool } from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import EditUserForm from './EditUserForm';
import DialogTitle from '../../../components/Dialog/DialogTitle/index'
import { compose } from 'recompose';
import { connect } from 'react-redux';
const styles = {
  rootStyle: {
    borderRadius: 0
  }
};
class AddNewUserPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:"",
            email:"",
            emailType:{
                Invoices:bool,
                Confirmations:bool,
                Cancellations:bool,
                Admin:bool
        },
            password:"",
            id:"",
            wasAdmin:undefined,
            isFormValid: true,
            isPasswordRequired:false
        };
    
        this.handleInputChange = this.handleInputChange.bind(this);
      }


  componentDidUpdate(prevProps){
      if(this.props.validateMessage.variant !== prevProps.validateMessage.variant){
        if(this.props.validateMessage.variant === "error" & !this.state.isPasswordRequired) {
          this.setState({isFormValid:false}) 
        }else {
          this.setState({isFormValid: true})
          this.handleClose()
        }
      }
  }

  handleClick = event => {
    this.setState({
      open:true,
      isNewUser:true
    });
  };

  handleClose = () => {
    this.setState({
      isNewUser:null,
      name:"",
      email:"",
      emailType:{
            Invoices:bool,
            Confirmations:bool,
            Cancellations:bool,
            Admin:bool
      },
      password:"",
      id:"",
      wasAdmin:undefined,
      open:false
    })
  };
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked: target.value;
    const name = target.name;
    
    if(name==="Confirmations"){
    let emailtype= Object.assign({},this.state.emailType)
    emailtype.Confirmations=value
    this.setState({
     emailType:emailtype
    })}
    else if (name==="Cancellations") {
      let emailtype= Object.assign({},this.state.emailType)
      emailtype.Cancellations=value
      this.setState({
       emailType:emailtype
      })
    } else if (name==="Invoices") {
    let emailtype= Object.assign({},this.state.emailType)
    emailtype.Invoices=value
    this.setState({
     emailType:emailtype
    })
        
    } else if (name==="Admin") {
    let emailtype= Object.assign({},this.state.emailType)
    emailtype.Admin=value
    this.setState({
     emailType:emailtype,
     isPasswordRequired:true
    })}
    if(name==="password"){
      this.setState({isFormValid:false,
        [name]:value
    })
    value.length >= 8 ? this.setState({ isFormValid:true , isPasswordRequired:false}) : this.setState({isPasswordRequired:true})
    }     
    else{
    this.setState({
      [name]:value
    })};
  }

  onSubmit = () => {
    !this.state.isPasswordRequired && this.props.handleCreate(this.state)  
  };
  
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Button
          aria-owns={this.state.open ? 'simple-popper' : undefined}
          aria-haspopup="true"
          variant="contained"
          onClick={this.handleClick}
          className="primary-button-background-color secondary-text-color box-shadow-none border-radius-0px primary-medium-font padding-top-bottom-8px padding-right-left-24px"
        >
          Add a New User 
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          classes={{paper: classes.rootStyle}}
        >
         <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            USER INFORMATION
         </DialogTitle>
            <EditUserForm
            name={this.state.name}
            email={this.state.email}
            handleInputChange={this.handleInputChange}
            invoices={this.state.emailType.Invoices}
            confirmations={this.state.emailType.Confirmations}
            cancellations={this.state.emailType.Cancellations}
            admin={this.state.emailType.Admin}
            password={this.state.password}
            open={this.state.open}
            onSubmit={this.onSubmit}
            onDelete={this.onDelete}
            isNewUser={this.state.isNewUser}
            isPasswordRequired={this.state.isPasswordRequired}
            />
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  validateMessage: state.businessData.message
})

const mapDispatchToProps = (dispatch) => ({

})

export default compose(connect(mapStateToProps,mapDispatchToProps), withStyles(styles))(AddNewUserPopUp);
