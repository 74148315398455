import React from "react";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import { authRequest} from '../../actions/auth';
import { withRouter } from 'react-router-dom';
import { getBusinessDataRequest, resetPasswordRequest } from '../../actions/businessDataActions'
import { Typography, Button } from "@material-ui/core";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forgot:false
    };
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleSubmit = event => {

    event.preventDefault();
    this.props.authRequest(this.state)
  };
  componentWillReceiveProps(nextProps){
    if(nextProps.isAuthorized !== this.props.isAuthorized ){
        this.props.getBusinessData()
    }
    if(nextProps.selectedBusiness !== this.props.selectedBusiness){
      window.gtag("event", "Logged_in")
      this.props.history.push(`/business/${nextProps.selectedBusiness}/home`)
    }
    if(nextProps.selectedChain !== this.props.selectedChain){
      window.gtag("event", "Logged_in")
      this.props.history.push(`/chain/${nextProps.selectedChain}/home`)
    }
  }
  forgotPassword=(event)=>{
    event.preventDefault()
    if(this.state.forgot===false){
      this.setState({
        forgot:true
      });
    }else{
      this.setState({
        forgot:false
      })
    }
  }

  resetPassword=()=>{
    this.props.resetPassword(this.state.email)
  }
  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="auth-form login-form ">
        {this.state.forgot===false ?
        <div>
          <div className="auth-flex-item login-form-input-container is-g-regular">
          
            <input
              type="text"
              placeholder="Email address"
              name="email"
              autoComplete="off"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="auth-flex-item login-form-input-container is-g-regular">
            <input
              type="password"
              placeholder="Password"
              name="password"
              autoComplete="off"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="auth-flex-item login-form-action-container is-g-regular">
            <Checkbox defaultChecked color="default" value="" /> Remember me
          </div>
          <div className="auth-flex-item login-form-button-container is-g-semiBold">
            <button
              type="submit"
              className="auth-button btn-hvr-an"
            >
              SIGN IN
            </button>
          </div>
          <div className="auth-flex-item login-form-button-container is-g-regular">
            <button type="button" onClick={this.forgotPassword} className="action-button">
              Forgot Password?
            </button>
          </div>
          </div> :
          <div> 
          <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
          
          <Typography style={{color:"white"}}>FORGOT PASSWORD/<br/> RESET PASSWORD</Typography>
          <Typography style={{marginTop:20, color:"white"}}>Please enter your email below.<br/> We'll send you a link to <br/> reset/ create your password</Typography>
          </div>
          <div className="auth-flex-item login-form-input-container is-g-regular">
          
          <input
            style={{marginTop:20}}
            type="text"
            placeholder="Email address"
            name="email"
            onChange={this.handleInputChange}
          />
        </div>
        <div className="auth-flex-item login-form-button-container is-g-semiBold">
          <Button
            style={{marginTop:20}}
            className="auth-button btn-hvr-an"
            onClick={this.resetPassword}
          >
            RESET PASSWORD
          </Button>
        </div>
        <div className="auth-flex-item login-form-button-container is-g-regular">
          <button type="button" onClick={this.forgotPassword} className="action-button">
            Cancel
          </button>
        </div>
        </div>}
        
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      isAuthorized:state.auth.isAuthorized,
      selectedBusiness:state.businessData.selectedBusiness,
      selectedChain:state.businessData.selectedChain
    }
}

const mapDispatchToProps = (dispatch) => {
    return { 
        authRequest: (data) => {dispatch(authRequest(data))},
        getBusinessData: () => {dispatch(getBusinessDataRequest())},
        resetPassword:(data) =>{dispatch(resetPasswordRequest(data))}
        
    }
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm));
