import React from "react";
import "./dashBoard.css";
import BoxView from "../../components/BoxView";
import BorderVertical from "../../components/BorderVertical";
import BorderHorizontal from "../../components/BorderHorizontal";
import { Grid } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";

function SahDashboard(props) {
    return (
        <Grid
              container
              direction="column"
              justify="space-evenly"
              style={{ marginBottom: 50 }}
            >
        <Grid container direction="row" justify="space-evenly">
            <BorderHorizontal />
            <Grid item container direction="row" justify="space-evenly">
                <Grid item style={{ paddingTop: 100 }}>
                <BoxView
                    title="Average Spend per order"
                    number={props.dashboardData.avgSpend}
                    hasDollars={true}
                />
                </Grid>
                <Hidden smDown>
                <Grid item style={{ paddingTop: 30 }}>
                    <BorderVertical />
                </Grid>
                </Hidden>
                <Grid item style={{ paddingTop: 100 }}>
                <BoxView
                    title="Number of orders sent"
                    number={props.dashboardData.numOfOrders}
                    hasDollars={false}
                />
                </Grid>
            </Grid>
        </Grid>
        <Hidden smDown>
        <Grid item>
          <BorderHorizontal />
        </Grid>
      </Hidden>
      </Grid>
    );
}

export default SahDashboard;